import React from 'react'

function RequestQuotation() {
    return (
        <>
            <section className="quatesect">
                <div className="container">
                    <h4 className='postyourRequest'>Post your request</h4>
                    <div className="row newformcontent">
                        <div className="col-md-12">
                            <form>
                                <div className="form-group">
                                    <div>
                                        <div className="new-post-form-container-form-label" style={{ marginBottom: '8px' }}>* Product name</div>
                                        <div className="new-post-form-container-form-desc">Enter product name or choose from <u>recommended products</u></div>
                                    </div>

                                    <input type="text" className="form-control next-input" id="exampleFormControlInput1" placeholder="Please Enter" />
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col">
                                            <div className="new-post-form-container-form-label" style={{ marginBottom: '8px' }}>* Select your budget range</div>
                                            <select className="form-control next-input" id="exampleFormControlSelect1">
                                                <option>Less than 1000</option>
                                                <option selected>1000-5000</option>
                                                <option>5000-10000</option>
                                                <option>10000-50000</option>
                                                <option>Greater than 50000</option>
                                            </select>
                                        </div>

                                        <div className="col">
                                            <div className="new-post-form-container-form-label" style={{ marginBottom: '27px' }}></div>
                                            <select className="form-control next-input" id="exampleFormControlSelect1">
                                                <option>USD</option>
                                                <option>INR</option>

                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="row">
                                        <div className="col">
                                            <div className="new-post-form-container-form-label" style={{ marginBottom: '8px' }}>* Sourcing quantity</div>
                                            <input type="text" className="form-control next-input" id="exampleFormControlInput1" placeholder="Please Enter" />
                                        </div>

                                        <div className="col">
                                            <div className="new-post-form-container-form-label" style={{ marginBottom: '27px' }}></div>
                                            <select className="form-control next-input" id="exampleFormControlSelect1">
                                                <option>Pieces</option>
                                                <option>Acres</option>

                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="row">

                                        <div className="col-md-6">
                                            <div className="new-post-form-container-form-label" style={{ marginBottom: '8px' }}>* Trade terms</div>
                                            <select className="form-control next-input" id="exampleFormControlSelect1">
                                                <option>FOB</option>
                                                <option>EXW</option>

                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="new-post-form-container-form-label" style={{ marginBottom: '8px' }}>* Detailed requirements</div>
                                            <textarea className="form-control next-input" placeholder='I am looking for...' id="exampleFormControlTextarea1" rows={3} defaultValue={""} />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="next-upload-formPage">
                                        <input type="file" className='uploadfilemng' />
                                        <img src="https://img.alicdn.com/imgextra/i2/O1CN01vFYNaU1ttGvyH9tIe_!!6000000005959-2-tps-64-64.png" data-spm-anchor-id="a2700.rfq_pc_mainform.0.i16.3dc452pK52pKO9" />
                                    </div>
                                    <div className="new-post-form-container-form-desc">Upload files with a max size of 10MB each (supported types: .txt/.doc/.xls/.pdf)</div>
                                </div>

                                <div className="form-group">
                                    <div>
                                        <div className="new-post-form-container-form-label" style={{ marginBottom: '8px' }}>Tell us more about your business</div>
                                        <div className="new-post-form-container-form-desc">Verify your business for quicker quotes from matching suppliers.</div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <label htmlFor="company">Company Name</label>
                                            <input type="text" className="form-control next-input" id="exampleFormControlInput1" placeholder="Please Enter" />
                                        </div>
                                        <div className="col">
                                            <label htmlFor="company">Business type</label>
                                            <select className="form-control next-input" id="exampleFormControlSelect1">
                                                <option>Online shop/store</option>
                                                <option>Manufacturer/factory</option>
                                                <option>Trading company</option>
                                                <option>Distributor/wholesaler</option>
                                                <option>Retailer</option>
                                                <option>Individual</option>
                                                <option selected>Other</option>

                                            </select>
                                        </div>
                                        <div className="col">
                                            <label htmlFor="company">Business email</label>
                                            <input type="text" className="form-control next-input" id="exampleFormControlInput1" placeholder="Please Enter" />
                                        </div>
                                    </div>
                                </div>

                                <hr />

                                <div className="form-group">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="gridCheck" />
                                        <label className="form-check-label" htmlFor="gridCheck">
                                            I agree to share my Business Card with quoted suppliers.
                                        </label>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="gridCheck" />
                                        <label className="form-check-label" htmlFor="gridCheck">
                                            I have read, understood and agreed to abide by the Buying Request Posting Rules
                                        </label>
                                    </div>
                                </div>

                                <div className="text-center">
                                    <button type='button' className='inviteSuppliers'>Invite suppliers to quote</button>
                                </div>

                            </form>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RequestQuotation;