import React from 'react'

function LendingPolicy() {
    return (
        <>
            <div>
                {/* End header*/}
                <section className="innerpage-banner  mediaanalyst-banner">
                    <div className="container">
                        <h4 className="breadcrumb  text-white">Lending Privacy Policy</h4>
                        <div className="innerpage-banner-content mx-auto ">
                            <div className="row">
                                <div className="col-lg-10 col-xs-12 my-auto">
                                    <h1 className="text-left text-white">Lending <span className="pl-green">Privacy Policy</span></h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*-- Static page  --*/}
                <section className="static-page my-5 policyPageOut">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl_dark-grey">
                                <p className="pl_dark-grey"><b>"Astafa"</b> is the trademark of Astafa Technology Private Limited ( <b>"Company"</b>, <b>"us"</b> and <b>"our"</b>), a company incorporated under the provisions of the Companies Act, 2013.</p>
                                <p className="pl_dark-grey">Our Privacy Policy is subject to change from time to time and we will make all reasonable efforts to intimate and update our customers (<b>"you"</b> and <b>"your"</b>) of any changes, if required via SMS/WhatsApp or notification on the Astafa mobile application (<b>"Application"</b>) and/or Astafa Website (<b>"Website"</b>) (collectively referred to as <b>"Platform"</b>) It is strongly recommended that you review our Privacy Policy as available on the Platform. In case you need any clarifications regarding this Privacy Policy, please do not hesitate to contact us at <a style={{ textDecoration: "underline" }} href="mailto:info@Astafa.in">info@Astafa.in</a></p>
                                <p>By using the Platform and/ or by providing your information for any Lending Products, you consent to the collection and use of the information you disclose on the Platform in accordance with this Privacy Policy, including but not limited to your consent for collecting, using, sharing, and disclosing your information as per this Privacy Policy.</p>
                                <h3 className="pl-black font-weight-medium">COLLECTION, STORAGE AND USE OF INFORMATION RELATED TO YOU</h3>
                                <p className="pl_dark-grey">We collect, store and use two basic types of information from you in conjunction with your use of the lending products and services facilitated through our Platform (<b>"Lending Product(s)"</b>) in partnership with RBI registered non-banking financial institution and/or banks (<b>"Lending Partner(s)"</b>) i.e. personal information, which is any information that exclusively identifies you (e.g., your name, email address, login IDs and password, telephone number, postal address, financial information (credit history, bank account details and business information) amongst others) and technical information, which is information that does not exclusively identify you but is related to you nonetheless such as, information about your interests, demographics etc.</p>
                                <h3 className="pl-black font-weight-medium">Personal Information</h3>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl_dark-grey">
                                <p>On the Platform, the following personal information is provided by you for availing any Lending Products:</p>
                                 <table className='tableLending' width="100%">
                                    <tbody >
                                        <tr>
                                            <td width={250}><b>Contact Information</b></td>
                                            <td>We may collect contact information to manage our relationship with you, communicate with you about our Lending Products, to verify your identity, to provide references for availing the Lending Products, to contact your references for verification and to reach out to you (if you are not available) and to undertake fraud checks.</td>
                                        </tr>
                                        <tr>
                                            <td width={250}>SMS Information</td>
                                            <td>Collect SMS information to facilitate our Lending Partners to perform a credit risk assessment. For example: we will send an automated message from your device to link and verify your phone number to authenticate and authorise your transaction.</td>
                                        </tr>
                                        <tr>
                                            <td width={250}>Camera</td>
                                            <td>For the purpose of completing your onboarding process and submitting relevant documents.</td>
                                        </tr>
                                        <tr>
                                            <td width={250}>Others</td>
                                            <td>As may be explicitly required & and consented by you from time to time for availing Lending Products.</td>
                                        </tr>
                                    </tbody>
                                 </table>
                                 <p>Before providing any reference(s) at the time of applying for and/or availing of any Lending Products, you undertake to take explicit consent from such reference(s). You shall also inform such person that Company or any entity/persons authorized by it, may contact them for conducting your background verification, reach out to you (if you're not available) and/or to undertake fraud checks to facilitate Lending Products from its Lending Partner and provide necessary services related to it. If you choose to provide any reference(s) for any of the Lending Products, the Company or the Platform shall deem that you have obtained consent from such person (whose reference is given) after disclosing the purpose for which their reference is provided. Please note that the Company or its Lending Partner or its authorized entity/person and/or the Platform shall be under no duty or obligation to ensure the authenticity or accuracy, or validity of the consent obtained by you from reference(s) or the non-availability of the consent from the references. You agree to indemnify and/or defend the Company, its Lending Partner and/or its authorized entity/person, against any loss, damages, civil or criminal proceedings and/or any liability which may arise and/or be caused due to breach of this obligation.</p>
                                 <p>When you engage in certain activities on the Platform such as creating an account, ordering/availing the Lending Product, posting a review, requesting information about our services etc., we may ask you to provide certain additional information about yourself.</p>
                                 <p>When you use our Platform, we collect and store information which is provided by you from time to time after seeking your explicit consent permissions from you to get the required information. Our primary goal in doing so is to provide you a safe, efficient, smooth, and customised experience and services. This allows us to provide services and features that meet your needs, and to customise our Platform to make your experience safer and easier and to improve the services provided by us. More importantly, we only collect personal information from you that we consider necessary for achieving the aforementioned purpose.</p>
                                 <p>To the extent possible, we provide you the option of not divulging any specific information that you wish for us not to collect, store or use. You may also choose not to use a particular Lending Product or its feature on the Platform and to opt out of any non-essential communications from the Company. Depending on the Lending Product you may want to avail/have availed, some of the information we ask you to provide is identified as mandatory and some is identified as voluntary. If you do not provide the mandatory information for a particular Lending Product or any of its features that is required, you will not be permitted to avail said Lending Product or feature (or will have only the limited Lending Product or feature).</p>
                                 <p>We only collect and use such information from you that we consider necessary for achieving a seamless, efficient, and safe experience, customised to your needs and use the personal information to provide the Lending Products to you, enhance the operation of the Platform, improve our marketing and promotional efforts, analyse the Platform use, improve our product offerings, to personalise your experience and to provide you a better experience. We also collect other identifiable information (your payment details, balances and transaction history on the Platform, demographic information, device location and device information) from you when you set up an account with us. While you may be able to browse some sections of our Platform without being a registered member as mentioned above, availing the Lending Products requires registration and for you to provide the above details. The Platform shall clearly display the personal information it is collecting from you, and you have the option to not provide such personal information. However, this will limit the services provided to you on the Platform.</p>

                                 <h3 className="pl-black font-weight-medium">Technical Information</h3>
                                 <p>When you access the Platform, we may collect certain information from you that is non-personally identifiable in nature (for example - the type of browser you are using, your internet protocol address, your preferences on the Platform etc.) This information is used for purposes such as serving and rendering ads, personalizing ads, analysing our Platform flow, measuring our promotional effectiveness and improves your experience of the Platform as it allows us to enhance our products and services provided to you via the Platform.</p>

                                 <h3 className="pl-black font-weight-medium">Information collected from you and shared with others</h3>
                                 <p>We may share information collected from you with different third parties on a "need-to-know" basis, including our Lending Partners for providing the Lending Products(for example - risk and credit underwriting, evaluation, due diligence checks, facilitating document collection and/or verification for KYC purposes, record keeping purposes, fraud checks, to undertake research and analytics for improving the Platform operations and services, customer service and grievance redressal, for collection services etc.)</p>
                                 <p>Where possible, you shall have the option to restrict disclosure to third parties. However, where sharing of information to a particular third party is necessary for providing the Lending Products, you shall not be able to avail such Lending Products in case you decide to restrict such disclosure. Following are the third parties to whom your information may be shared for providing/enabling the Lending Products:</p>
                                 <table className='tableLending'>
                                 <thead>
                                        <tr>
                                            <th width="50%">Type of Third Party</th>
                                            <th width="50%">Description</th>
                                        </tr>
                                    </thead>
                                 <tbody>
                                        <tr>
                                            <td>Group companies of the Company</td>
                                            <td>We may share your information to our subsidiaries, affiliates, and group companies in order to efficiently provide certain Lending Products or particular features, for internal corporate/business restructuring purposes and audit related purposes etc. in compliance with applicable laws.</td>
                                        </tr>
                                        <tr>
                                            <td >Banking partners, payment processing partners, co-branding partners, lending partner and third-party vendors</td>
                                            <td>We may share your information/information submitted by you to financial institutions and service providers such as payment processors, card issuers, NBFC partners, collection service providers and banking partners to facilitate transactions/for providing such Lending Product.</td>
                                        </tr>
                                        <tr>
                                            <td >IT Service Providers</td>
                                            <td>We may share your information to IT Service Providers who are engaged in the provision of information technology services for fulfilling various aspects of the Platform and for research and data analytics.</td>
                                        </tr>
                                        <tr>
                                            <td>Identity and other information verification providers</td>
                                            <td>We may share your information to identity and other information verification to complete identification and information verification processes in accordance with applicable laws.</td>
                                        </tr>
                                        <tr>
                                            <td>Government and regulatory organisations</td>
                                            <td>We may share your information with the Government, law enforcement agencies, authorities, and regulatory bodies when the Company must comply with its legal obligations.</td>
                                        </tr>
                                        <tr>
                                            <td>Marketing, business development, and sales partners</td>
                                            <td>We may share your information with marketing, business development and sales partners for the purpose of improving and carrying out marketing or business development activities to provide better services to you through our Platform.</td>
                                        </tr>

                                    </tbody>
                                 </table>
                                 <p>If you send us personal correspondence, such as emails or letters, or if other users or third parties send us correspondence about your activities on the Platform, we may collect and store such information for providing services and for record keeping purposes.</p>
                                 <h3 className="pl-black font-weight-medium">Withdrawal of Consent</h3>
                                 <p>You may withdraw your consent given for collecting any information and personally identifiable information that is stored with us, at any time by sending an email to <a href="mailto:info@Astafa.in">info@Astafa.in</a>. Once you have withdrawn consent to use your personally identifiable information stored with us, we will stop actively processing your personally identifiable information but may store it for archival/record keeping purposes in compliance with applicable laws. In such an event, we reserve the right to not allow you further usage of our Platform or provide/use any services thereunder, without any obligations or liability, whatsoever, whether in contract, tort, law, equity or otherwise, in this regard.</p>
                                 <h3 className="pl-black font-weight-medium">How long we keep your data</h3>
                                 <p>We will keep your information as long as you are a borrower and/or in the event where you have availed any of the Lending Product through the Platform.</p>
                                 <p>We may keep your information after you stop being a borrower/customer of a Lending Product. The reasons we may do this are:</p>
                                 <ul>
                                    <li>To respond to a question or complaint, or to show whether we gave you fair treatment</li>
                                    <li>To establish, exercise or defend our legal claims</li>
                                    <li>To comply with legal rules that apply to us about keeping records or information in which case we will retain your data for a minimum of 5 (five) years after your account has been terminated or longer depending on applicable laws.</li>
                                 </ul>
                                 <h3 className="pl-black font-weight-medium">Links to other Sites</h3>
                                 <p>The Platform may contain links to third party applications/websites that may collect personally identifiable information about you. In the event you access such third party applications/websites, please be aware that the privacy policy of such third party shall be applicable on the information collected from such applications/websites. Please refer to the privacy policies of such third parties before sharing any data with them.</p>
                                 <h3 className="pl-black font-weight-medium">Storage and Security Precautions</h3>
                                 <p>Our servers are located within the territory of India, where your data is stored securely.</p>
                                 <p>The Platform has reasonable security measures as per the Information Technology Act, 2000 in place to protect the loss or misuse of the information under our control. We implement reasonable physical, administrative, and technical safeguards to help us protect your personal information from unauthorized access, use and disclosure. For example, we encrypt all sensitive personal information when we transmit such information over the internet. We also require that our registered third-party service providers protect such information from unauthorized access, use and disclosure.</p>
                                 <p>Our Platform has stringent security measures in place to protect the loss, misuse, and alteration of information under control. We endeavour to safeguard and ensure the security of the information provided by you. We use Secure Sockets Layers (SSL) based encryption, for the transmission of the information, as per the applicable law.</p>
                                 <p>We blend security at multiple steps within our products with the state-of-the-art technology to ensure our systems maintain strong security measures and the overall data and privacy security design allow us to defend our systems ranging from low hanging issue up to sophisticated attacks.</p>
                                 <p>Please note that we or our Lending Partners or any person authorized by us will not ask you to share any sensitive data or information such as account/login/passwords/financial information (bank details, OTPs etc) and other sensitive personal information via email/telephone/SMS/link. Please do not share such information to any person even if such persons claims to be the authorized representative of us and/or any of our partners. However, transacting over the internet has inherent risks which can only be avoided by you following security practices yourself, such as not revealing account/login/passwords/financial information such as bank details, OTPs and other sensitive personal information to any other person. In the event you suspect that your account/personal information has/may have been compromised or you receive any suspicious call, SMS, link, email requesting your sensitive personal information, you may reach out to our grievance officer and inform him of the same.</p>
                                 <h3 className="pl-black font-weight-medium">Communication With Company</h3>
                                 <p>If you wish to correct or update any information you have provided, you may do so online, on the Platform itself. Alternatively, you may contact the Company to correct or update such information by sending an e-mail to the grievance officer mentioned below.</p>
                                 <p>In the event of loss of access to the Platform, you may contact the Company by sending an e-mail to the grievance officer.</p>
                                 <p>In the event you wish to report a breach of the Privacy Policy, you may contact the designated Grievance Officer of the Company at:</p>
                                 <p>
                                    <b>Grievance Officer</b> <br />
                                 <b>Name: Ubaid Deshmukh</b><br />
                                 <b>Email: <a href="mailto:info@Astafa.in">info@Astafa.in</a></b> <br />
                                 [9:30 AM to 6:30 PM, Monday to Friday except Public Holidays]

                                 </p>
                                 <h3 className="pl-black font-weight-medium">Governing Law and Jurisdiction</h3>
                                 <p>This Privacy Policy will be governed by and construed in accordance with the laws of India and subjected to the exclusive jurisdiction of Courts of Mumbai.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default LendingPolicy;