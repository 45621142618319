import React from 'react';
import {Link} from "react-router-dom";

function HeaderPart() {
    return (
        <>
            <header className="header-static navbar-sticky header navbar-light">
                {/* Logo Nav Start */}
                <nav className="navbar navbar-expand-lg">
                    <div className="container">
                        {/* Logo */}
                        <a className="navbar-brand" href="/">
                            <img src="assets/img/logo.png" alt="Pine labs Logo" className="header-logo" />
                            {/* SVG Logo End */}
                        </a>
                        {/* Menu opener button */}
                        <div className=" ml-auto mobile-hamburger-menu">
                            <a area-label="contact-button" title="button" className="btn-primary-green border-0 py-1 px-2 float-left d-md-none1 m-view-contact" href="#">
                                <i className="fa fa-phone text-white" aria-hidden="true" />
                            </a>
                            <span className="backmenu-btn float-left ml-2" id="left_arrow"><i className="fa fa-long-arrow-left pl-black" aria-hidden="true" /></span>
                            <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                                <div className="animated-icon"><span /><span /><span /><span /></div>
                            </button>
                        </div>
                        {/* Main Menu Start */}
                        <div className="navbar-collapse collapse" id="navbarCollapse" style={{}}>
                            <ul className="navbar-nav ml-auto sum-menu-center mm-0">
                                {/* Menu item 1 Demos*/}

                                <li className="nav-item dropdown">
                                    <Link className="nav-link" to="/products">Products</Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link className="nav-link" to="/financing">Financing</Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link className="nav-link" to="/about">Partnership</Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link className="nav-link" to="/about">Careers</Link>
                                </li> 
                                {/* <li className="nav-item dropdown">
                                    <Link className="nav-link" to="requestQuotation">RFQ</Link>
                                </li> */}
                                <li className="nav-item dropdown">
                                    <Link className="nav-link" to="contact">Contact us</Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link className="downloadOurApp" to="applyLoan">Apply Loan</Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link className="requestQuotation" to="requestQuotation">RFQ</Link>
                                </li>

                                {/* <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="demosMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">About Us</a>
                                    <div className="dropdown-menu bulky-dropmenu pb-lg-0" aria-labelledby="demosMenu">
                                        <div className="d-block d-sm-flex">
                                            <div className="list-unstyled  py-3">
                                                <h3 className="dropdown-header"><span className="pl-green">360-degree</span> Payments
                                                    Solutions</h3>
                                                <div className="row">
                                                    <div className="col"> <a className="dropdown-item" href="#">
                                                        <span className="instore_icon icon-48" />
                                                        <span className="text-menu-1">In-Store</span></a>
                                                        <p>Accept all major payment modes and upgrade your customer experience
                                                        </p>
                                                    </div>
                                                    <div className="col">
                                                        <a className="dropdown-item" href="#">
                                                            <span className="onlinepayments_icon icon-48" />
                                                            <span className="text-menu-1">Online</span></a>
                                                        <p>Make online payments faster, simpler and error-free.</p>
                                                    </div>
                                                    <div className="col"> <a className="dropdown-item" href="#">
                                                        <span className="omnichannel_icon icon-48" />
                                                        <span className="text-menu-1">Omnichannel</span></a>
                                                        <p>Sell in-store and online seamlessly</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li> */}
                                {/* Menu item 2 Blog*/}
                                {/* <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="blogMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Platform</a>
                                    <div className="dropdown-menu" aria-labelledby="blogMenu">
                                        <div className="d-block d-sm-flex">
                                            <div className="list-unstyled  py-3">
                                                <h3 className="dropdown-header"><span className="pl_new_teal">Growing businesses</span>
                                                    across sector</h3>
                                                <div className="row">
                                                    <div className="col-sm-4 col-6"> <a className="dropdown-item" href="#">
                                                        <span className="electronics_icon icon-48" />
                                                        <span className="text-menu-1">Electronics</span></a>
                                                    </div>
                                                    <div className="col-sm-4 col-6"> <a className="dropdown-item" href="#">
                                                        <span className="lifestyle_icon icon-48" />
                                                        <span className="text-menu-1">Lifestyle</span></a>
                                                    </div>
                                                    <div className="col-sm-4 col-6"> <a className="dropdown-item" href="#">
                                                        <span className="auto_icon icon-48" />
                                                        <span className="text-menu-1">Automobile</span></a>
                                                    </div>
                                                    <div className="col-sm-4 col-6"> <a className="dropdown-item" href="#">
                                                        <span className="grocery_icon icon-48" />
                                                        <span className="text-menu-1">Grocery</span></a>
                                                    </div>
                                                    <div className="col-sm-4 col-6"> <a className="dropdown-item" href="#">
                                                        <span className="healthcare_icon icon-48" />
                                                        <span className="text-menu-1">Healthcare</span></a>
                                                    </div>
                                                    <div className="col-sm-4 col-6"> <a className="dropdown-item" href="#">
                                                        <span className="hospitality_icon icon-48" />
                                                        <span className="text-menu-1">Hospitality</span></a>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <a className="dropdown-item setupmenu-text" href="#">
                                                            <span className="setupbusiness_icon icon-48" />
                                                            <span className="text-menu-1 ml-2">Starting up your business? Set up
                                                                with Pine Labs.</span></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li> */}
                                {/* Menu item 3 Pages*/}
                                {/* <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="DomoreMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Products</a>
                                    <div className="dropdown-menu" aria-labelledby="DomoreMenu">
                                        <div className="d-block d-sm-flex">
                                            <div className="list-unstyled  py-3">
                                                <h3 className="dropdown-header"><span className="pl_new_light-blue">Do More</span> for
                                                    your Customer and your Business</h3>
                                                <div className="row">
                                                    <div className="col">
                                                        <h6 className="fs-12 pl_new_light-blue">For your customer</h6>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"> <a className="dropdown-item" href="#">
                                                        <span className="paylater_icon icon-48" />
                                                        <span className="text-menu-1">Pay Later</span></a>
                                                    </div>
                                                    <div className="col"> <a className="dropdown-item" href="#">
                                                        <span className="loyalty_icon icon-48" />
                                                        <span className="text-menu-1">Loyalty</span></a>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"> <a className="dropdown-item" href="#">
                                                        <span className="fave_icon icon-48" />
                                                        <span className="text-menu-1">Rewards <span className="text-lowercase">and
                                                        </span><br />Cashback</span></a>
                                                    </div>
                                                    <div className="col"> <a className="dropdown-item" href="#">
                                                        <span className="gifting_icon icon-48" />
                                                        <span className="text-menu-1">Prepaid <span className="text-lowercase">and
                                                        </span><br />Gifting</span></a>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <h6 className="mt-3 fs-12 pl_new_light-blue">For your business operations
                                                        </h6>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-4 col-6"> <a className="dropdown-item" href="#">
                                                        <span className="pinelabsone_icon icon-48" />
                                                        <span className="text-menu-1">Pine Labs <br />One</span></a>
                                                    </div>
                                                    <div className="col-sm-4 col-6"> <a className="dropdown-item" href="#">
                                                        <span className="analytics_icon icon-48" />
                                                        <span className="text-menu-1">Analytics</span></a>
                                                    </div>
                                                    <div className="col-sm-4 col-6"> <a className="dropdown-item" href="#">
                                                        <span className="appintegrations_icon icon-48" />
                                                        <span className="text-menu-1">Utility App <br />Integrations</span></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li> */}
                                {/* Menu item 4 Docs*/}
                                {/* <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="developerMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Financing</a>
                                    <div className="dropdown-menu four-col-menu" aria-labelledby="developerMenu">
                                        <div className="d-block d-sm-flex">
                                            <div className="list-unstyled  py-3">
                                                <h3 className="dropdown-header">Use our <span className="pl_new-bright-blue">developer
                                                    friendly</span> platform to build apps for thousands of Merchants</h3>
                                                <div className="row">
                                                    <div className="col"> <a className="dropdown-item" href="#">
                                                        <span className="developerhome_icon icon-48" />
                                                        <span className="text-menu-1">Developer</span></a>
                                                    </div>
                                                    <div className="col"> <a className="dropdown-item" href="#">
                                                        <span className="platform_icon icon-48" />
                                                        <span className="text-menu-1">Platform</span></a>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"> <a className="dropdown-item" href="#">
                                                        <span className="api_icon icon-48" />
                                                        <span className="text-menu-1">APIs</span></a>
                                                    </div>
                                                   
                                                    <div className="col"> <a className="dropdown-item" href="#">
                                                        <span className="support_icon icon-48" />
                                                        <span className="text-menu-1">Support</span></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li> */}
                                {/* <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="getCreditMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Financing</a>
                                    <div className="dropdown-menu get-credit" aria-labelledby="getCreditMenu">
                                        <div className="d-block d-sm-flex">
                                            <div className="list-unstyled  py-3">
                                                <h3 className="dropdown-header">Purchase materials <span className="pl-green">on credit</span></h3>
                                                <div className="row">
                                                    <div className="col-md-6 col-12 mobile-w-100">
                                                        <div className="row m-0">
                                                            <a className="col-md-12 dropdown-item mobile-flex" href="/financing">
                                                                <span className="workingcapital_icon icon-48" />
                                                                <span className="text-menu-1 ml-2">Working Capital</span>
                                                            </a>
                                                        </div>
                                                        <div className="row sub-menu-line ml-0 mm-0">
                                                            <div className="col-md-12 col-6 mobile-ml-3">
                                                                <a className="dropdown-item" href="/financing">
                                                                    <span className="flexicredit_icon icon-48" />
                                                                    <span className="text-menu-1 ml-0">FlexiCredit</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12 mm-0">
                                                        <div className="row m-0">
                                                            <a className="col-md-12 dropdown-item mobile-flex" href="/financing">
                                                                <span className="getcredit_partner_icon icon-48" />
                                                                <span className="text-menu-1 ml-2">Our Partners</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li> */}
                                {/* <li className="nav-item dropdown">
                                    <Link className="nav-link" to="/contact">Contact Us</Link>
                                </li> */}
                            </ul>
                            {/* Main Menu End */}
                            {/* Header Extras Start*/}
                            {/* <div class="navbar-nav navbar-right">
                  <div
                      class="nav-item contact-select border-0 pl-0 m-view pr-3 d-md-flex align-items-center d-none">
                      <a class="btn-small btn-primary-green text-white py-2 px-3 font-weight-medium"
                          href="contact-sales.html">
                          Contact Us
                      </a>
                  </div> */}
                            {/* extra item language*/}{/* Language */}
                            {/*	<div class="nav-item language-select border-0 pl-3 pr-0 px-lg-2 d-flex align-items-center">
                  
                  <label class="d-none d-xs-block">Language</label>
                  <div class="dropdown">
                    <a class="dropdown-toggle" href="#" role="button" id="dropdownLanguage" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <img class="dropdown-item-icon" src="assets/images/flags/uk.svg" alt=""> English </a>
                    <div class="dropdown-menu dropdown-menu-language shadow" aria-labelledby="dropdownLanguage">
                      <a class="dropdown-item" href="#"><img class="dropdown-item-icon" src="assets/images/flags/sp.svg" alt=""> Hindi</a>
                      <a class="dropdown-item" href="#"><img class="dropdown-item-icon" src="assets/images/flags/fr.svg" alt=""> Marathi</a>
                      <a class="dropdown-item" href="#"><img class="dropdown-item-icon" src="assets/images/flags/gr.svg" alt=""> Tamil</a>
                    </div>
                  </div>
                  
                 </div>*/}
                            {/* extra item Btn*/}
                            {/* Header Extras End*/}
                            {/* </div> */}
                        </div>
                    </div></nav>
                {/* Logo Nav End */}
            </header>

        </>
    )
}

export default HeaderPart