import logo from './logo.svg';
import './App.css';
import Home from './component/pages/Home';
import HeaderPart from './component/pages/HeaderPart';
import FooterPart from './component/pages/FooterPart';
import { Route, BrowserRouter, Routes } from "react-router-dom";
import ContactUs from './component/pages/ContactUs';
import AboutUs from './component/pages/AboutUs';
import ContactSales from './component/pages/ContactSales';
import Faq from './component/pages/Faq';
import PrivacyPolicy from './component/pages/PrivacyPolicy';
import LendingPolicy from './component/pages/LendingPolicy';
import TermsCondition from './component/pages/TermsCondition';
import Financing from './component/pages/Financing';
import RequestQuotation from './component/pages/RequestQuotation';
import Products from './component/pages/Products';
import ApplyLoan from './component/pages/ApplyLoan';

function App() {
  return (
    <>
      <BrowserRouter>
        <HeaderPart />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<AboutUs />} />
          <Route path='/contact' element={<ContactUs />} />
          <Route path='/contactSales' element={<ContactSales />} />
          <Route path='/faq' element={<Faq />} />
          <Route path='/privacyPolicy' element={<PrivacyPolicy />} />
          <Route path='/lendingPolicy' element={<LendingPolicy />} />
          <Route path='/termsCondition' element={<TermsCondition />} />
          <Route path='/financing' element={<Financing />} />
          <Route path='/requestQuotation' element={<RequestQuotation />} />
          <Route path='/products' element={<Products />} />
          <Route path='/applyLoan' element={<ApplyLoan />} />
          <Route path="*" element={<h1>Page not Found</h1>} />
        </Routes>
        <FooterPart />
      </BrowserRouter>
    </>
  );
}

export default App;
