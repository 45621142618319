import React from 'react'

function ContactSales() {
    return (
        <div>
            <div>
                <section className="innerpage-banner  contactsales-banner">
                    <div className="container">
                        <h4 className="breadcrumb text-white"> Contact</h4>
                        <div className="innerpage-banner-content mx-auto ">
                            <div className="row">
                                <div className="col-lg-12 col-xs-12 my-auto">
                                    <h1 className="text-left text-white mb-3">Solving Challenges. Ushering Growth. <span className="pl-green">Reach out to us.</span></h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*------------------Products
----------------------------------*/}
                <section className="contactsales-section mt-5 mb-5">
                    <span className=" green-shadow-blur-right" />
                    <div className="container">
                        <div className="row">
                            <div className="contactsales-tabs col-lg-4 col-xs-12 p-lg-0">
                                <div className="active" id="merchant_type_new">
                                    <h3 className="pl_medium-grey"> New to Astafa? <span className="arrow-grey bg-pl-green" id="arrow_new" /></h3>
                                    <p className="small">Give us a few details to help us kickstart your future-positive journey</p>
                                </div>
                                <div className id="merchant_type_existing">
                                    <h3 className="pl_medium-grey">Already with Astafa? <span className="arrow-grey bg-pl-green" id="arrow_existing" style={{ display: 'none' }} /></h3>
                                    <p className="small">Our latest products will help your business work smoothly and grow even faster. Fill out the form and we’ll equip you with everything you need.</p>
                                </div>
                            </div>
                            <div className="form-cards col-lg-8 col-xs-12" id="FormBasicDetails">
                                <div className="card">
                                    <div className="card-body">
                                        <form method="POST" className="forms" id="form_submit" autoComplete="on" action="#">
                                            <input type="hidden" name="g-token" id="g_token" defaultValue />
                                            <input type="hidden" name="oid" defaultValue="00D0o00000175zn" />
                                            <input type="hidden" name="retURL" defaultValue="contact-us/thank-youc0de.html?source=salesforce-contact-sales" />
                                            {/*  these lines if you wish to test in debug mode.                          */}
                                            {/* <input type="hidden" name="debug" value=1>                              
                  <input type="hidden" name="debugEmail" value="pinelabs-dev-sfdc@comprotechnologies.com">           
                       */}
                                            {/*  ----------------------------------------------------------------------  */}
                                            <div className="form_1" id="form_1">
                                                <div className="form-pagination pagination1-3"><p className="small m-0 py-2" id="page_no1"> Step 1 to 2</p></div>
                                                <h4 className="pl-green my-4">What products are you looking for?</h4>
                                                <p id="error_cont_page" />
                                                <div className="row">
                                                    <div className="form-group col-lg-12 col-12">
                                                        <label htmlFor="currentaccount" className="mt-3 pl_dark-green">Please select the products you're intrested in (you can select more than one)</label>
                                                        <div className="contact_product_detail">
                                                            <div className="form-check custom-control custom-checkbox">
                                                                <input type="checkbox" name="00N0o00000NmqPQ" className="custom-control-input form-check-input multiple_records" defaultChecked defaultValue="P10100" id="pos-machine" />
                                                                <label className="form-check-label custom-control-label" htmlFor="pos-machine">PoS Machine</label>
                                                            </div>
                                                            {/* <div class="form-check custom-control custom-checkbox">
                          <input type="checkbox" name="00N0o00000NmqPQ" class="custom-control-input form-check-input multiple_records"  value="palm" id="palm">
                          <label class="form-check-label custom-control-label" for="palm">Palm</label>
                      </div> */}
                                                            <div className="form-check custom-control custom-checkbox">
                                                                <input type="checkbox" name="00N0o00000NmqPQ" className="custom-control-input form-check-input multiple_records" id="payment-gateway" defaultValue="P11200" /><label className="form-check-label custom-control-label" htmlFor="payment-gateway">Payment Gateway
                                                                </label>
                                                            </div>
                                                            <div className="form-check custom-control custom-checkbox">
                                                                <input type="checkbox" name="00N0o00000NmqPQ" className="custom-control-input form-check-input multiple_records" id="payment-qr" defaultValue="Payment QR" /><label className="form-check-label custom-control-label" htmlFor="payment-qr">Payment QR
                                                                </label>
                                                            </div>
                                                            <div className="form-check custom-control custom-checkbox">
                                                                <input type="checkbox" name="00N0o00000NmqPQ" id="emi" className="custom-control-input form-check-input multiple_records" defaultValue="PAY LATER EMI" /><label className="form-check-label custom-control-label" htmlFor="emi">Pay Later EMI
                                                                </label>
                                                            </div>
                                                            <div className="form-check custom-control custom-checkbox">
                                                                <input type="checkbox" name="00N0o00000NmqPQ" className="custom-control-input form-check-input multiple_records" defaultValue="Smart Analytics" id="smart-analytics" /><label className="form-check-label custom-control-label" htmlFor="smart-analytics">Smart Analytics
                                                                </label>
                                                            </div>
                                                            <div className="form-check custom-control custom-checkbox">
                                                                <input type="checkbox" name="00N0o00000NmqPQ" className="custom-control-input form-check-input multiple_records" defaultValue="P10800" id="loyalty" /><label htmlFor="loyalty" className="form-check-label custom-control-label">Loyalty
                                                                </label>
                                                            </div>
                                                            {/* <div class="form-check custom-control custom-checkbox">
                         <input type="checkbox" name="00N0o00000NmqPQ" class="custom-control-input form-check-input multiple_records" id="epos"  value="P12400"><label for="epos" class="form-check-label custom-control-label">AllTap
                         </label>
                      </div>*/}
                                                            <div className="form-check custom-control custom-checkbox" id="working_capital_div">
                                                                <input type="checkbox" name="00N0o00000NmqPQ" className="custom-control-input form-check-input multiple_records" defaultValue="GetCredit" id="working-capital" /><label className="form-check-label custom-control-label" htmlFor="working-capital">Get Credit
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <span className="red small" id="error_multiple_records" />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-lg-6 col-12" id="how_many_machine_need" style={{ display: 'none' }}>
                                                        <label htmlFor="text" className="mt-3 pl_dark-green">How many machine do you need?*</label>
                                                        <select className="form-control" name="00N0o00000OUIEf" id="pos_machine_need">
                                                            <option value>Number of PoS required*</option>
                                                            <option value="1 to 9">1 to 9</option>
                                                            <option value="10 to 100">10 to 100</option>
                                                            <option value="100+">100+</option>
                                                        </select>
                                                        <div className="invalid-feedback">How many machine do you need?</div>
                                                        <span className="red small" id="error_pos_machine_need">
                                                        </span>
                                                    </div>
                                                    <div className="form-group col-lg-12 col-12" id="flexi_cc_ad" style={{ display: 'none' }}>
                                                        <label htmlFor="text" className="mt-3 pl_dark-green">Please select Get Credit products*</label>
                                                        <div className="contact_product_detail">
                                                            <div className="form-check custom-control custom-checkbox">
                                                                <input type="checkbox" name="00N0o00000OUIEg" className="get_credit custom-control-input form-check-input" id="Flexi Credit" defaultValue="Flexi Credit" /><label className="form-check-label custom-control-label" htmlFor="Flexi Credit">Flexi Credit
                                                                </label>
                                                            </div>
                                                            {/* <div class="form-check custom-control custom-checkbox">
                                  <input type="checkbox" name="00N1e00000OzwzM"] class="get_credit custom-control-input form-check-input" id="Credit_Card" value="Credit card"><label class="form-check-label custom-control-label" for="Credit_Card">Credit Card
                                </label>
                              </div> */}
                                                            <div className="form-check custom-control custom-checkbox">
                                                                <input type="checkbox" name="00N0o00000OUIEg" className="get_credit custom-control-input form-check-input" id="Advance_Settlement" defaultValue="Advanced Settlement" /><label className="form-check-label custom-control-label" htmlFor="Advance_Settlement">Advance Settlement
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <span className="red small" id="get_credit_error">
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form_2" id="form_2" style={{ display: 'none' }}>
                                                <div className="form-pagination pagination2-3">
                                                    <p className="small m-0 py-2" id="page_no2">Step 2 to 3</p>
                                                </div>
                                                <h4 className="pl-green my-4">Tell us a little about your business</h4>
                                                <p id="error_cont_page" />
                                                <div className="row">
                                                    <div className="form-group col-lg-6 col-12">
                                                        <label htmlFor="name" className="pl_dark-green">First Name*</label>
                                                        <input type="text" className="form-control" id="first_name" name="first_name" autoComplete="on" placeholder="Please enter your first name" />
                                                        <div className="invalid-feedback">Please provide a valid first name.</div>
                                                        <span className="red small" id="error_first_name" />
                                                    </div>
                                                    <div className="form-group col-lg-6 col-12">
                                                        <label htmlFor="name" className="pl_dark-green">Last Name*</label>
                                                        <input type="text" className="form-control" id="last_name" name="last_name" placeholder="Please enter your last name" />
                                                        <div className="invalid-feedback">Please provide a valid last name.</div>
                                                        <span className="red small" id="error_last_name" />
                                                    </div>
                                                    <div className="form-group col-lg-6 col-12">
                                                        <label htmlFor="state" className="mt-3 pl_dark-green">State*</label>
                                                        <select className="form-control js-example-basic-single" name="00N0o00000Nmbe4" id="state" onchange="updateCity()">
                                                            <option value>State*</option>  <option value="AN"> Andaman &amp; Nicobar </option>  <option value="AP"> Andhra Pradesh </option>  <option value="AR"> Arunachal Pradesh </option>  <option value="AS"> Assam </option>  <option value="BI"> Bihar </option>  <option value="CG"> Chandigarh UT </option>  <option value="CH"> Chattisgarh </option>  <option value="DN"> Dadra and Nagar Haveli and Daman &amp; Diu </option>  <option value="DL"> Delhi </option>  <option value="GA"> Goa </option>  <option value="GJ"> Gujarat </option>  <option value="HR"> Haryana </option>  <option value="HP"> Himachal Pradesh </option>  <option value="JK"> Jammu &amp; Kashmir </option>  <option value="JH"> Jharkhand </option>  <option value="KT"> Karnataka </option>  <option value="KR"> Kerala </option>  <option value="LA"> Ladakh </option>  <option value="LD"> Lakshadweep </option>  <option value="MP"> Madhya Pradesh </option>  <option value="MH"> Maharashtra </option>  <option value="MN"> Manipur </option>  <option value="MG"> Meghalaya </option>  <option value="MZ"> Mizoram </option>  <option value="NG"> Nagaland </option>  <option value="OR"> Orissa </option>  <option value="PY"> PondiCherry UT </option>  <option value="PJ"> Punjab </option>  <option value="RJ"> Rajasthan </option>  <option value="SK"> Sikkim </option>  <option value="TN"> Tamil Nadu </option>  <option value="TG"> Telangana </option>  <option value="TR"> Tripura </option>  <option value="UC"> Uttaranchal </option>  <option value="UP"> Uttar Pradesh </option>  <option value="WB"> West Bengal </option>     </select>
                                                        <span className="red small" id="error_state" />
                                                    </div>
                                                    <div className="form-group col-lg-6 col-12">
                                                        <label htmlFor="city" className="mt-3 pl_dark-green">City*</label>
                                                        <select className="form-control js-example-basic-single" name="00N0o00000OUIEe" id="city">
                                                            <option value>City*</option>
                                                        </select>
                                                        <span className="red small" id="error_city" />
                                                    </div>
                                                    <div className="form-group col-lg-6 col-12">
                                                        <label htmlFor="email" className="mt-3 pl_dark-green">Email ID*</label>
                                                        <input type="email" className="form-control" id="email" name="email" placeholder="Your Email ID goes here" />
                                                        <div className="invalid-feedback">Please provide a valid Email ID.</div>
                                                        <span className="red small" id="error_email_id" />
                                                    </div>
                                                    <div className="form-group col-lg-6 col-12">
                                                        <label htmlFor="phone" className="mt-3 pl_dark-green">Contact Number*</label>
                                                        <input type="tel" className="form-control" id="mobile_number" maxLength={10} onkeypress="return isNumberKey(event);" name="phone" placeholder="+91- ––––– –––––" />
                                                        <div className="invalid-feedback">Please provide a valid phone number.</div>
                                                        <span className="red small" id="error_mobile_number" />
                                                    </div>
                                                    <div className="form-group col-lg-6 col-12" id="current_ac_cond" style={{}}>
                                                        <label htmlFor="currentaccount" className="mt-3 pl_dark-green">Do you have a current account?*</label>
                                                        <div className="d-flex">
                                                            <div className="radio mr-3">
                                                                <input type="radio" name="00N0o00000Nmbdl" id="radio1" defaultValue="Yes" />
                                                                <label htmlFor="radio1">Yes</label>
                                                            </div>
                                                            <div className="radio">
                                                                <input type="radio" name="00N0o00000Nmbdl" id="radio2" defaultValue="No" />
                                                                <label htmlFor="radio2">No</label>
                                                            </div>
                                                        </div>
                                                        <span className="red small" id="error_current_account" />
                                                    </div>
                                                    <div className="d-flex col-lg-12 col-12">
                                                        {/* //merchant type */}
                                                        <input type="hidden" name="00N0o00000Nmbdm" id="merchant_type" defaultValue="new" />
                                                        {/* //- */}
                                                        <input type="hidden" name="lead_source" id="lead_source" defaultValue="Web-To-Lead" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form_3" id="form_3" style={{ display: 'none' }}>
                                                <div className="form-pagination pagination3-3">
                                                    <p className="small m-0 py-2" id="page_no2">Step 3 to 3</p>
                                                </div>
                                                <h4 className="pl-green my-4">Verify your mobile number</h4>
                                                <p id="error_cont_page" />
                                                <div className="row">
                                                    <div className="form-group col-lg-6 col-12">
                                                        <label htmlFor="name" className="pl_dark-green">Enter OTP shared on your mobile number*</label>
                                                        <input type="tel" className="form-control" id="otpverify" name="otpverify" onkeypress="return isNumberKey(event);" autoComplete="on" defaultValue />
                                                        <div className="invalid-feedback">Enter OTP shared on your mobile number</div>
                                                        <span className="red small" id="error_otpverify" />
                                                        <p id="resend_button" className="mt-2 text-right small" role="button" onclick="return resendOTP();">Resend OTP</p>
                                                        <p id="resend_text" className="mt-3 d-none" />
                                                    </div>
                                                    <div className="d-flex col-lg-12 col-12">
                                                        <input type="hidden" name="page_source" id="page_source" defaultValue="contact-sales" />
                                                        <input type="hidden" name="url_redirection" id="url_redirection" defaultValue="direct" />
                                                        <input type="hidden" name="utm_parameter" id="utm_parameter" defaultValue />
                                                        <input type="hidden" name="referer" id="referer" defaultValue="index.html" />
                                                        <input type="hidden" name="change" id="change" defaultValue={1} />
                                                        <input type="hidden" name="product" id="product" defaultValue="pos-machine" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row form-group mt-3 col-lg-12 col-12">
                                                <p>By clicking Submit, you are agreeing to the <a href="terms-of-use.html">Terms of Use</a> and <a href="privacy-policy.html">Privacy Policy</a> and also agree to be contacted with and to receive offers via email/sms.</p>
                                            </div>
                                            <button type="button" className="btn btn-secondary-green mr-2" style={{ display: 'none' }} id="prev">Prev</button>
                                            <button type="button" className="btn btn-primary-green" id="next">Next</button>
                                            <button type="button" className="btn btn-primary-green" style={{ display: 'none' }} id="next_sub">Next</button>
                                            <button type="button" className="btn btn-primary-green" style={{ display: 'none' }} name="otp_verification" id="otp_verification">Submit</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default ContactSales;