import React from 'react'

function PrivacyPolicy() {
    return (
        <>
            <div>
                {/* End header*/}
                <section className="innerpage-banner  mediaanalyst-banner">
                    <div className="container">
                        <h4 className="breadcrumb  text-white">  Privacy Policy</h4>
                        <div className="innerpage-banner-content mx-auto ">
                            <div className="row">
                                <div className="col-lg-10 col-xs-12 my-auto">
                                    <h1 className="text-left text-white"> Privacy <span className="pl-green">Policy</span></h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*-- Static page  --*/}
                <section className="static-page my-5 policyPageOut">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl_dark-grey">
                                <p className="pl_dark-grey">This Privacy Notice describes how Astafa Technology Private Limited and our affiliates (collectively, "Astafa Technology," "we," and "us") collect, use, disclose, transfer, store, retain or otherwise process your information when you (whether you are a person or business) apply or sign up for/on a Astafa Technology PoS account or other services (including, but not limited to, Astafa Technology Lending) through Astafa Technology website or product applications (collectively "Services").</p>
                                <p className="pl_dark-grey">This Privacy Notice applies to your use of our website and/or Services, and covers information collected in connection with your access to and use of our website and/or Services. Please read this Privacy Notice carefully. By continuing to interact with us, you are consenting to the practices described in this Privacy Notice.</p>
                                <h3 className="pl-black font-weight-medium">INFORMATION WE COLLECT OR RECEIVE ABOUT YOU</h3>
                                <p className="pl_dark-grey">We need to collect or receive information about you to provide you with the Services or the support you request. The type of information can vary depending on the country from which you access our Services. Additionally, you may also and can choose to voluntarily provide information to us.</p>
                                <h3 className="pl-black font-weight-medium">Information You Provide</h3>
                                <p className="pl_dark-grey">We collect or receive information you provide when you apply, use or sign up for/on a Astafa Technology PoS or other Services, go through our identity or account verification process, authenticate into your account, communicate with us, answer our surveys, upload content, or otherwise use the Services.</p>
                                <p className="pl_dark-grey">We collect or receive information about you when you use our Pos and/or Services, including:</p>
                                <ul>
                                    <li><b>Identification Information</b>. Your name; email address; mailing address; mobile/phone number; photograph; birthdate; passport, driver's license, PAN, or other government-issued identification; or other historical, contact, and demographic information when you apply, use or sign up for a Astafa Technology PoS and/or other Services, signature, and authentication credentials (for example, information you use to login to your account), including IP address.</li>
                                    <li><b>Financial Information</b>. Information such as bank account, payment card numbers, credit reports, and other publicly available information.</li>
                                    <li><b>Tax information</b>. PAN details.</li>
                                    <li><b>Transaction Information</b>. When you use our PoS and/or Services to make, accept, request, or record payments, we receive information about when and where the transactions occur, the names of the transacting parties, a description of the transactions, the payment or transfer amounts, billing and shipping information, and the devices and payment methods used to complete the transactions.</li>
                                    <li><b>Other Information You Provide</b>. Information that you voluntarily provide to us, including any survey responses; participation in contests, promotions, or other prospective seller marketing forms or devices; suggestions for improvements; referrals; or any other actions performed on the Services.</li>
                                </ul>
                                <h3 className="pl-black font-weight-medium">Information We Collect Or Receive From Your Use of our PoS and/or Services</h3>
                                <p className="pl_dark-grey">We collect or receive information about you and the devices you use to access the PoS and/or Services, such as your computer, mobile phone, or tablet. The information that we collect or receive includes:</p>
                                <ul>
                                    <li>Precise Geolocation Information. The location of your device.</li>
                                    <li>Device Information. Information about your device, including your hardware model, operating system and version, device name, unique device identifier, mobile network information, and information about the device's interaction with our Services.</li>
                                    <li><b>Use Information</b>. Information about how you use our Services, including your access time, "log-in" and "log-out" information, browser type and language, country and language setting on your device, Internet Protocol ("IP") address, the domain name of your Internet service provider, other attributes about your browser, mobile device and operating system, any specific page you visit on our website/platform, content you view, features you use, the date and time of your visit to or use of the Services, your search terms, the website you visited before you visited or used the Services, data about how you interact with our Services, and other clickstream data.</li>
                                    <li><b>Business Information</b>. Information about products and services you sell (including inventory, pricing and other data) and other information you provide about you or your business.</li>
                                    <li><b>Customer Information</b>. Information you collect from your customers, including email address, phone number, payment information, or other information.</li>
                                </ul>
                                <h3 className="pl-black font-weight-medium">Information We Collect Or Receive From Other Sources</h3>
                                <p className="pl_dark-grey">We also collect or receive information about you from third parties, including:</p>
                                <ul>
                                    <li><b>Identity Verification </b>. Information from third-party verification services, credit bureaus, financial institutions, mailing list providers, and publicly available sources. In some circumstances, where lawful, this information may include your government-issued identification number.</li>
                                    <li><b>Background Information</b>. To the extent permitted by applicable laws, we may obtain background check reports from public records of criminal convictions and arrest records. We may use your information, including your full name, government-issued identification number, and date of birth, to obtain such reports.</li>
                                    <li><b>Credit, Compliance and Fraud</b>. Information about you from third parties in connection with any credit investigation, credit eligibility, identity or account verification process, fraud detection process, or collection procedure, or as may otherwise be required by applicable law. This includes, without limitation, the receipt and exchange of account or credit-related information with any credit reporting agency or credit bureau, where lawful, and any person or corporation with whom you have had, currently have, or may have a financial relationship, including without limitation past, present, and future places of employment, financial institutions, and personal reporting agencies.</li>
                                </ul>
                                <h3 className="pl-black font-weight-medium">Children's Information</h3>
                                <p className="pl_dark-grey">Our Services are general audience services not directed at children under the age of 13. If we obtain actual knowledge that any information we collect or receive has been provided by a child under the age of 13, we will promptly delete that information.</p>
                                <h3 className="pl-black font-weight-medium">HOW WE USE YOUR INFORMATION</h3>
                                <p className="pl_dark-grey">We may use information about you for number of purposes, including:</p>
                                <h3 className="pl-black font-weight-medium">Providing, Improving, and Developing our Services</h3>
                                <ul>
                                    <li>Determining whether the Services are available in your country;</li>
                                    <li>Processing or recording payment transactions or money transfers;</li>
                                    <li>Otherwise providing you with the Astafa Technology products and features you choose to use;</li>
                                    <li>Displaying your historical transaction or appointment information;</li>
                                    <li>Providing, maintaining and improving our Services;</li>
                                    <li>Developing new products and services;</li>
                                    <li>Delivering the information and support you request, including technical notices, security alerts, and support and administrative messages including to resolve disputes, collect fees, and provide assistance for problems with our Services or your Astafa Technology account;</li>
                                    <li>Improving, personalizing, and facilitating your use of our Services;</li>
                                    <li>Measuring, tracking, and analyzing trends and usage in connection with your use or the performance of our Services.</li>
                                </ul>
                                <h3 className="pl-black font-weight-medium">Communicating with You About our Services</h3>
                                <ul>
                                    <li>Sending you information we think you may find useful or which you have requested from us about our products and services;</li>
                                    <li>Conducting surveys and collecting feedback about our Services.</li>
                                </ul>
                                <h3 className="pl-black font-weight-medium">Protecting our Product and Services and Maintaining a Trusted Environment</h3>
                                <ul>
                                    <li>Investigating, detecting, preventing, or reporting fraud, misrepresentations, security breaches or incidents, other potentially prohibited or illegal activities, or to otherwise help protect your account, including to dispute chargebacks on your behalf;</li>
                                    <li>Protecting our, our customers', or your customers' rights or property, or the security or integrity of our Services;</li>
                                    <li>Enforcing our Terms of Use or other applicable agreements or policies;</li>
                                    <li>Verifying your identity (e.g., through government-issued identification numbers);</li>
                                    <li>Complying with any applicable laws or regulations, or in response to lawful requests for information from the government or through legal process;</li>
                                    <li>Fulfilling any other purpose disclosed to you in connection with our Services;</li>
                                    <li>Contacting you to resolve disputes, collect fees, and provide assistance with our Services.</li>
                                </ul>
                                <h3 className="pl-black font-weight-medium">Advertising and Marketing</h3>
                                <ul>
                                    <li>Communicating with you about opportunities, products, services, contests, promotions, discounts, incentives, surveys, and rewards offered by us and select partners;</li>
                                    <li>If we send you marketing emails, each email will contain instructions permitting you to "opt out" of receiving future marketing or other communications.</li>
                                </ul>
                                <h3 className="pl-black font-weight-medium">Other Uses</h3>
                                <ul>
                                    <li>For any other purpose disclosed to you in connection with our Services from time to time.</li>
                                </ul>
                                <h3 className="pl-black font-weight-medium">HOW WE SHARE YOUR INFORMATION</h3>
                                <ul>
                                    <li>We may share information about you as follows:</li>
                                </ul>
                                <h3 className="pl-black font-weight-medium">With Other Users of our Services with Whom You Interact</h3>
                                <ul>
                                    <li>With other users of our Services with whom you interact through your own use of our Services. For example, we may share information when you make or accept a payment, appointment, or money transfer using our Services.</li>
                                </ul>
                                <h3 className="pl-black font-weight-medium">With our Affiliates</h3>
                                <ul>
                                    <li>With our group companies and corporate affiliates, for the purposes outlined herein.</li>
                                </ul>
                                <h3 className="pl-black font-weight-medium">With Third Parties</h3>
                                <ul>
                                    <li>With third parties to provide, maintain, and improve our Services, including service providers who access information about you to perform services on our behalf (e.g., fraud prevention, identity verification, and fee collection services), as well as financial institutions, payment networks, payment card associations, credit bureaus, partners providing services on Astafa Technology behalf, and other entities in connection with the Services;</li>
                                    <li>With third parties that run advertising campaigns, contests, special offers, or other events or activities on our behalf or in connection with our Services.</li>
                                </ul>
                                <h3 className="pl-black font-weight-medium">Business Transfers and Corporate Changes</h3>
                                <ul>
                                    <li>To a subsequent owner, co-owner, or operator of one or more of the Services; or</li>
                                    <li>In connection with (including, without limitation, during the negotiation or due diligence process of) a corporate merger, consolidation, or restructuring; the sale of substantially all of our stock and/or assets; financing, acquisition, divestiture, or dissolution of all or a portion of our business; or other corporate change.</li>
                                </ul>
                                <h3 className="pl-black font-weight-medium">Safety and Compliance with Law</h3>
                                <ul>
                                    <li>If we believe that disclosure is reasonably necessary (i) to comply with any applicable law, regulation, legal process or governmental request (e.g., from tax authorities, law enforcement agencies, etc.); (ii) to enforce or comply with applicable agreements or policies; (iii) to protect our or our customers' rights or property, or the security or integrity of our Services; or (iv) to protect us, users of our Services or the public from harm, fraud, or potentially prohibited or illegal activities.</li>
                                </ul>
                                <h3 className="pl-black font-weight-medium">With Your Consent</h3>
                                <ul>
                                    <li>With your consent. For example:</li>
                                    <li>At your direction or as described at the time you agree to share;</li>
                                    <li>When you authorize a third-party application or website to access your information.</li>
                                </ul>
                                <h3 className="pl-black font-weight-medium">Aggregated and Anonymized Information</h3>
                                <ul>
                                    <li>We also may share (within our group of companies or with third parties) aggregated and anonymized information that does not specifically identify you or any individual user of our Services.</li>
                                </ul>
                                <h3 className="pl-black font-weight-medium">HOW LONG WE RETAIN YOUR INFORMATION</h3>
                                <p className="pl_dark-grey">We generally retain your information as long as reasonably necessary to provide you the Services or to comply with applicable law. However, even after you deactivate your account, we can retain copies of information about you and any transactions or Services in which you may have participated for a period of time that is consistent with applicable law, applicable statute of limitations or as we believe is reasonably necessary to comply with applicable law, regulation, legal process, or governmental request, to detect or prevent fraud, to collect fees owed, to resolve disputes, to address problems with our Services, to assist with investigations, to enforce our Terms of Use or other applicable agreements or policies, or to take any other actions consistent with applicable law.</p>
                                <h3 className="pl-black font-weight-medium">COOKIES AND OTHER SIMILAR TECHNOLOGIES</h3>
                                <p className="pl_dark-grey">We use various technologies to collect or receive information when you access or use our Services, including placing a piece of code, commonly referred to as a "cookie," or similar technology on your device and using web beacons. Cookies are small data files that are stored on your hard drive or in your device memory when you visit a website or view a message. Among other things, cookies support the integrity of our registration process, retain your preferences and account settings, and help evaluate and compile aggregated statistics about user activity. We will begin collecting information about you or from activity on devices you use as soon as you use the PoS and/or our Services. By using our Services, you permit us to collect or receive and use your information from activity on devices you use in accordance with this Privacy Notice.</p>
                                <p className="pl_dark-grey">Certain cookies we use last only for the duration of your web or application session and expire when you close your browser or exit the application. Other cookies are used to remember you when you return to use the Services and, as such, will last longer.</p>
                                <p className="pl_dark-grey">We may use cookies to:</p>
                                <ul>
                                    <li>Remember that you have visited us or used the Services before. This allows us to identify the number of unique visitors we receive, so that we can provide enough capacity to accommodate all of our users.</li>
                                    <li>Customize elements of the promotional layout and/or content of our Services.</li>
                                    <li>Collect data about the way you interact with our Services (e.g., when you use certain features).</li>
                                    <li>Collect data to assess and improve our advertising campaigns, including sending information to our business partners.</li>
                                    <li>Allow our business partners (including third parties) to use these tracking technologies to track your behavior on our behalf on our website/platform (including when you use multiple devices) and on partner websites.</li>
                                    <li>Enable third parties to collect data about the way you interact across sites outside of our Services.</li>
                                    <li>Collect anonymous statistical information about how you use the Services (including the length of your web or application session) and the location from which you access the Services, so that we can improve the Services and learn which elements and functions of the Services are most popular with our users.</li>
                                </ul>
                                <p className="pl_dark-grey">Some of the cookies used in the Services are set by us, and others are set by third parties who deliver services on our behalf.</p>
                                <p className="pl_dark-grey">Most web and mobile device browsers are set to automatically accept cookies by default. However, you can change your browser settings to prevent automatic acceptance of cookies, or to notify you each time a cookie is set. Please note, however, that by blocking or deleting cookies used in the Services, you may not be able to take full advantage of the Services.</p>
                                <p className="pl_dark-grey">We also may collect or receive information using web beacons. Web beacons are electronic images that may be used in our Services or emails. We use web beacons to deliver cookies, track the number of visits to our website and apps, understand usage and campaign effectiveness, and determine whether an email has been opened and acted upon.</p>
                                <h3 className="pl-black font-weight-medium">THIRD-PARTY ADVERTISING AND ANALYTICS</h3>
                                <ul>
                                    <li>We can use third-party service providers to provide site metrics and other analytics services. These third parties can use cookies, web beacons, and other technologies to collect information, such as your IP address, identifiers associated with your device, other applications on your device, the browsers you use to access our Services, webpages viewed, time spent on webpages, links clicked, and conversion information (e.g., transactions entered into). This information can be used by Astafa Technology and third-party service providers on behalf of Astafa Technology to analyze and track usage of our Services, determine the popularity of certain content, and better understand how you use our Services. The third-party service providers that we engage are bound by confidentiality obligations and other restrictions with respect to their use and collection of your information.</li>
                                    <li>This Privacy Notice does not apply to, and we are not responsible for, third-party cookies, web beacons, or other tracking technologies, which are covered by such third parties' privacy policies. For more information, we encourage you to check the privacy policies of these third parties to learn about their privacy practices. </li>
                                </ul>
                                <h3 className="pl_dark-grey font-weight-900">YOUR CHOICES</h3>
                                <h3 className="pl-black font-weight-medium">Personal Information</h3>
                                <p className="pl_dark-grey">You may access, change, or correct information that you have provided by logging into your Astafa Technology account at any time or by making a request to us on : <a href="mailto:astafa280@gmail.com">astafa280@gmail.com</a>, in which case we may need to verify your identity before granting access or otherwise changing or correcting your information.</p>
                                <h3 className="pl-black font-weight-medium">Deactivating Your Account</h3>
                                <p className="pl_dark-grey">If you wish to deactivate your account, you can do so by logging into your Astafa Technology account or by emailing us on : <a href="mailto:astafa280@gmail.com">astafa280@gmail.com</a>.</p>
                                <h3 className="pl-black font-weight-medium">Location Information</h3>
                                <p className="pl_dark-grey">In order to provide certain Services, we may require access to location information, including precise geolocation information collected from your device. If you do not consent to collection of this information, certain Services will not function properly and you will not be able to use those Services. </p>
                                <h3 className="pl-black font-weight-medium">Promotional Communications</h3>
                                <p className="pl_dark-grey">You can opt out of receiving promotional messages from Astafa Technology by following the instructions in those messages, by informing the caller that you would not like to receive future promotional calls, or by changing your notification settings by logging into your Astafa Technology account. You may only opt-out of text messages from Astafa Technology by replying STOP. Opting out of receiving communications may impact your use of the Services. If you decide to opt out, we can still send you non-promotional communications, such as digital receipts and messages about your account or our ongoing business relations. </p>
                                <h3 className="pl-black font-weight-medium">SECURITY</h3>
                                <p className="pl_dark-grey">We take reasonable measures, including administrative, technical, and physical safeguards, to protect your information from loss, theft, misuse, and unauthorized access, disclosure, alteration, and destruction. Nevertheless, the internet is not a 100% secure environment, and we cannot guarantee absolute security of the transmission or storage of your information. We hold information about you both at our own premises and with the assistance of third-party service providers.</p>
                                <h3 className="pl-black font-weight-medium">CHANGES TO THIS PRIVACY NOTICE</h3>
                                <p className="pl_dark-grey">We may amend this Privacy Notice from time to time by posting a revised version and updating the "Effective Date" above. The revised version will be effective on the "Effective Date" listed. We will provide you with reasonable prior notice of material changes in how we use your information, including by email, if you have provided an email address. If you disagree with these changes, you may cancel your account at any time. Your continued use of our Services constitutes your consent to any amendment of this Privacy Notice.</p>
                                <h3 className="pl-black font-weight-medium">CONTACT GRIEVANCE OFFICER</h3>
                                <p className="pl_dark-grey">Please contact our Customer Care Department and/or Grievance Officer - Santosh Pandey with any questions or concerns regarding this Privacy Notice and/or security by writing an email on: <a href="mailto:astafa280@gmail.com">astafa280@gmail.com</a></p>
                                <p className="pl_dark-grey">If you have any questions or concerns regarding our notice, or if you believe our notice or applicable laws relating to the protection of your personal information have not been respected, you may file a complaint with our Privacy Department listed above, and we will respond to let you know who will be handling your matter and when you can expect a further response. We may request additional details from you regarding your concerns and may need to engage or consult with other parties in order to investigate and address your issue. We may keep records of your request and any resolution.</p>

                            </div>

                        </div>
                    </div>
                </section>
            </div>

        </>
    )
}

export default PrivacyPolicy;