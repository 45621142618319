import React from 'react'

function AboutUs() {
  return (
    <>
      <div>
        <section className="innerpage-bannerAbout">
          <div className="container">
            <div className="innerpage-banner-content">
              <div className="row">
                <div className="col-lg-11 col-md-9 col-sm-12 col-xs-12 my-auto">
                  <h5 className="text-left"><span className="pl-green">Astafa is a tech-enabled platform that facilitates building construction materials and FMEG (Fast Moving Electrical Goods) with embedded purchase credit. The platform integrates merchants/ shops and manufactures/suppliers for key materials at better price and convenience. </span></h5>
                  <div className="col-12 mt-4 p-0  inner-header-content">
                    <p>We have partnered with multiple Non-Banking Finance Companies (NBFC) to offer purchase credit to its merchants/shops. </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='benefitsshop'>
              <h4>Benefits to shops for working with Astafa</h4>
              <ul>
                <li>Better prices with convenience, we guarantee discounted rates from market</li>
                <li>Convenience of order (app, website, WhatsApp, call or in person)</li>
                <li>Hassle-free next day delivery: we cover more than 6500 pins across India</li>
                <li>Instant credit up to Rs. 5 lacs with no hidden fees</li>
              </ul>
            </div>
          </div>
        </section>
        {/*------------------Products
----------------------------------*/}
        {/* <section className="vision-misson-section space-ptb">
          <div className="gradient-green col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
            <div className="container">
              <div className=" col-lg-11 col-md-11 col-sm-12 col-xs-12 mx-auto py-5">
                <div className="row row-flex">
                  <div className="col-lg-6 col-xs-12 ">
                    <h3 className="white-strip text-white mb-4" id>Our Vision</h3>
                    <p className=" text-white mb-5">We believe that every business can grow exponentially with technology and capital.</p>
                  </div>
                  <div className="col-lg-6 col-xs-12" id>
                    <h3 className="white-strip text-white mb-4">Our Mission </h3>
                    <p className=" text-white mb-4">Maniacal focus on creating a product and services platform that widens access and accelerates commerce for merchants in each local market we operate in.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section className="roadmap-block space-ptb">
          <div className="container">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h2 className="text-left">Be <span className="pl-green"></span>our partner</h2>
            </div>
            {/* <div className=" col-lg-11 col-lg-offset-1 col-md-11 col-sm-12 col-xs-12">
        <div className="row">
          <article className="timeline">
            <nav className="timeline__nav">
              <ul>
                <li className="active"><span>1998</span></li>
                <li><span>2009 - 2012</span></li>
                <li><span>2012 - 2017</span></li>
                <li><span>2017 - Today</span></li>
              </ul>
            </nav>
            <section className="timeline__section">
              <div className="wrapper">	
                <div className="milestone">
                  <h3 className=" pl-green">Pine Labs was Founded</h3>
                  <p>When Pine Labs began, our main focus was on smart card-based payment and <a href="products/loyalty-solutions.html">loyalty solutions</a> for the retail petroleum industry.</p>
                </div> 
                &nbsp;&nbsp;&nbsp;	<div className="milestone">
                  &nbsp;&nbsp;&nbsp;<h3 className="  pl-green"> Going Mainstream </h3>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<p>Eleven years later we made our first forays into the world of mainstream payments, giving merchants solutions and connecting them with banks and financial services.</p>
                </div>
                <div className="milestone">
                  &nbsp;	&nbsp;&nbsp;<h3 className=" pl-green"> Our First Unified PoS Platform </h3>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<p>We pioneered the use of the smart, cloud-based unified point-of-sale platform – designed to reduce costs and drive retail revenues.</p>
                  &nbsp;&nbsp;<h3 className=" pl-green">Becoming Digital-ready</h3>
                  <p>We partnered with banks and payment aggregators to make sure our <a href="products/pos-card-machine.html">PoS terminal</a> could process all forms of digital payments to enhance the merchant-customer experience.</p>
                  &nbsp;<h3 className=" pl-green">A Complete Merchant Platform</h3>
                  &nbsp;<p>We evolved into a platform that provides merchants with solutions for payments, risk assessment, multi-channel analytics, lending &amp; insurance, brand offers, cashbacks, integrated billing and more.</p>
                  &nbsp;<h3 className=" pl-green">We built a network of</h3>
                  &nbsp;&nbsp;<p>Multiple financial services and brands</p>
                </div>
                <div className="milestone">
                  &nbsp;&nbsp;&nbsp;<h3 className=" pl-green"> Going Global </h3>
                  &nbsp;&nbsp;&nbsp;&nbsp;<p>2017 saw us lay our first global footprint as we entered the Malaysian market with an exclusive partnership with CIMB Bank.</p>
                  &nbsp;<h3 className=" pl-green"> Growing the Merchant Platform of the Future</h3>
                  &nbsp;<p>Today, we are on our way to building one of the world’s most robust fintech platforms to meet the needs of the modern merchant.</p>
                </div>
              </div>
            </section>
          </article>
        </div>
      </div> */}
            
          </div>
        </section>
      </div>

    </>
  )
}

export default AboutUs;