import React from 'react'
import { Link } from 'react-router-dom'

function FooterPart() {
	return (
		<>
			<footer className="bg-pl-black">
				<div className="container">
				<div className="footer-top  accordion" id="accordion">
						<div className="row">
							
							<div className="col-md-4 col-sm-12">
								
								<div className="footer-top-li-second footer-accordion-item">
									<h5 className="footer-accordion-item-head" data-toggle="collapse" href="#contact">About Us<span className="caret" /></h5>
									<ul id="contact" className="collapse" data-parent="#accordion">
										<li><Link to="/about">About Us</Link></li>
										<li><Link to="/contact">Partnership</Link></li>
										<li><Link to="/faq"> Careers </Link></li>
									</ul>
								</div>
							</div>
							<div className="col-md-4 col-sm-12">
								
								<div className="footer-top-li-second footer-accordion-item">
									<h5 className="footer-accordion-item-head" data-toggle="collapse" href="#contact">Policy &amp;
										Terms<span className="caret" /></h5>
									<ul id="contact" className="collapse" data-parent="#accordion">
										<li><Link to="privacyPolicy">Privacy Policy</Link></li>
										<li><Link to="termsCondition">Terms & Conditions</Link></li>
										<li><Link to="lendingPolicy">Lending Privacy Policy</Link></li>
									</ul>
								</div>
							</div>
							<div className="col-md-4 col-sm-12">
								<div className="footer-top-li-second footer-accordion-item">
									<h5 className="footer-accordion-item-head" data-toggle="collapse" href="#contact">Sales &amp;
										Support<span className="caret" /></h5>
									<ul id="contact" className="collapse" data-parent="#accordion">
										<li><Link to="contactSales">Contact Sales</Link></li>
										<li><Link to="contact">Support</Link></li>
										<li><Link to="faq"> FAQs </Link></li>
										<li><Link to="requestQuotation"> Request for Quotation </Link></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className="footer-bottom">
						<div className="row">
							<div className="col-md-6 col-3 country-web footerbottom-accordion-item">
							<p className=" p-0 pci-dss">© 2023 Astafa</p>
							</div>
							<div className="col-md-6 col-9 float-right">
								<ul className="social-network">
									<li><a target="_blank" rel="nofollow" href="#" className="icoFacebook" title="Facebook"><img src="assets/img/icons/social-media/Facebooka6d4.svg?version=6.1.4" className="icon-24" alt="Pine labs facebook" /></a></li>
									<li><a target="_blank" rel="nofollow" href="#" className="icoLinkedin" title="Linkedin"><img src="assets/img/icons/social-media/LinkedIna6d4.svg?version=6.1.4" className="icon-24" alt="Pine labs Linkedin" /></a></li>
									<li><a target="_blank" rel="nofollow" href="#" className="icoTwitter" title="Twitter"><img src="assets/img/icons/social-media/Twittera6d4.svg?version=6.1.4" className="icon-24" alt="Pine labs twiteer" /></a></li>
									<li><a target="_blank" rel="nofollow" href="#" className="icoyoutube" title="youtube"><img src="assets/img/icons/social-media/Youtubea6d4.svg?version=6.1.4" className="icon-24" alt="Pine labs Youtube" /></a></li>
									<li><a target="_blank" rel="nofollow" href="#" className="icoinstagram" title="instagram"><img src="assets/img/icons/social-media/Instagrama6d4.svg?version=6.1.4" className="icon-24" alt="Pine labs instagram" /></a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</footer>

		</>
	)
}

export default FooterPart