import React from 'react'

function Financing() {
    return (
        <>
            <section className="innerpage-banner  mediaanalyst-banner">
                <div className="container">
                    <div className="innerpage-banner-content mx-auto ">
                        <div className="row">
                            <div className="col-lg-12 col-xs-12 textfinancier">
                                <h1 className="text-left text-white">Financing <span className="pl-green"></span></h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*------------------Feature Product
----------------------------------*/}
            <section>
                <h2 className=" text-center mb-md-5 mb-sm-0"><span className="pl-green">Purchase materials </span> <br />on credit</h2>
                <div className='gradient-green'>
                    <div className="container">
                        <div className="line-grid-management">

                            <div className="payment-block-content pt-4">
                                <div className="col-lg-12 col-md-12 col-sm-12  col-xs-12 p-0">
                                    <div className="line-grid-small-bg" />
                                    <div className="row row-flex">
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 d-flex  align-items-stretch">
                                            <div className="card">
                                                <div className="card-body">
                                                    <img src="assets/img/icons/quality.png" className="icon-64" alt="In Store payments" />
                                                    <h4 className="pt-3 pl_dark-grey">Quick Verification and Cash Flow</h4>
                                                    <p className="card-text">Verify your business instantly on the AFB (Astafa for Business) platform and get funds in 24 - 72 hours<sup>*</sup></p>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 d-flex  align-items-stretch">
                                            <div className="card">
                                                <div className="card-body">
                                                    <img src="assets/img/icons/investment.png" className="icon-64" alt="Online payments" />
                                                    <h4 className="pt-3  pl_dark-grey">Risk-free Assets</h4>
                                                    <p className="card-text">Get cash against your company's unpaid invoices. No need to pledge any assets</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 d-flex  align-items-stretch">
                                            <div className="card">
                                                <div className="card-body">
                                                    <img src="assets/img/icons/borrow.png" className="icon-64" alt="Omnichannel" />
                                                    <h4 className="pt-3  pl_dark-grey">Avoid New Debt</h4>
                                                    <p className="card-text">Grow your business without impacting your balance sheet</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* ----------------------------------------- */}

                                <div className="col-lg-12 col-md-12 col-sm-12 mt-4  col-xs-12 p-0">
                                    <div className="line-grid-small-bg" />
                                    <div className="row row-flex">
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 d-flex  align-items-stretch">
                                            <div className="card">
                                                <div className="card-body">
                                                    <img src="assets/img/icons/real-time.png" className="icon-64" alt="In Store payments" />
                                                    <h4 className="pt-3 pl_dark-grey">Real-time Updates</h4>
                                                    <p className="card-text">Track your invoice status on the go through AFB (Astafa for Business) self-serve module</p>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 d-flex  align-items-stretch">
                                            <div className="card">
                                                <div className="card-body">
                                                    <img src="assets/img/icons/paperless.png" className="icon-64" alt="Online payments" />
                                                    <h4 className="pt-3  pl_dark-grey">Paperless Process</h4>
                                                    <p className="card-text">Upload your documents and invoices easily on the AFB (Astafa for Business) platform</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 d-flex  align-items-stretch">
                                            <div className="card">
                                                <div className="card-body">
                                                    <img src="assets/img/icons/strategic-plan.png" className="icon-64" alt="Omnichannel" />
                                                    <h4 className="pt-3  pl_dark-grey">End-to-end Management</h4>
                                                    <p className="card-text">smooth and transparent process from invoice listing ...</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="freaturefinanc">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p>&#9635;  Short term credit given shops and merchants for paying their dues to suppliers/wholesalers</p>
                            <p>&#9635;  Limit upto Rs. 5 lacs</p>
                            <p>&#9635;  Loan tenure up to 2 months</p>
                            <p>&#9635;  Zero interest rates, transaction fees upto 2%, processing fees of 0% to 3%</p>
                            <p>&#9635;  Usually utilized for stocks, extending more credit or replacing trade credit and for replenishing working capital used for consumption.</p>
                            <p>&#9635;  Hassle free loans: Quick turnaround time</p>
                        </div>
                        <div className="col-md-12 mt-5">
                            <h2>BASIC ELIGIBILITY CRITERIA</h2>
                            <h3>Customer Profile:</h3>
                            <p>&#9635;  Shop/ merchant in building construction/electrical/hardware</p>
                            <h3>Minimum Business Vintage</h3>
                            <p>&#9635;  2 years</p>
                            <h3>Property Ownership</h3>
                            <p>&#9635;	Ownership of at least 1 property is mandatory</p>
                            <p>&#9635;  Ownership of shop/ lease agreement</p>
                            <h2>DOCUMENTS REQUIRED</h2>
                            <p>&#9635;  Photograph - Latest Colour Photograph of proprietor, owner</p>
                            <p>&#9635;	Identity proof of promoters (Anyone) - Voter ID Card / PAN Card / Passport / Driving License / Aadhar Card / Govt. issued document + Photograph / Bank Sign Verification + Photo</p>
                            <p>&#9635;	KYC documents of Firm/ proprietor</p>
                            <p>&#9635;  Income Proof - Audited/ Unaudited financial statements with all schedules / ITR of last 3 Years</p>
                            <p>&#9635;	Bank Statement – Last 1 year bank statement of all bank accounts</p>
                            <p>&#9635;	Existing facility sanction letter/ Repayment Track Record (RTR) of existing loan, if any</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Financing