import React from 'react'

function Products() {
    return (
        <>
            <section className="ourProduct">
            <h2 className="text-center"><span className="pl-green">India's Leading Manufacturer Of</span><br/> Electrical Accessories</h2>
                <div className="container producttopmng">
                    <div className="row mgnbginBottom">
                        {/* _______________________________________ */}

                        <div className="col-md-3">
                            <div className="productDetails">
                                <div className="imgDetails">
                                    <img src="assets/img/home/mcc-slider/switch_socket.png" alt="product" />
                                </div>
                                <p>Switches and sockets</p>
                            </div>
                        </div>

                        {/* _______________________________________ */}

                        <div className="col-md-3">
                            <div className="productDetails">
                                <div className="imgDetails">
                                    <img src="assets/img/home/mcc-slider/conduit-pipes.png" alt="product" />
                                </div>
                                <p>PVC conduit pipes</p>
                            </div>
                        </div>

                        {/* _______________________________________ */}

                        <div className="col-md-3">
                            <div className="productDetails">
                                <div className="imgDetails">
                                    <img src="assets/img/home/mcc-slider/casing-capping.png" alt="product" />
                                </div>
                                <p>Casing- Capping</p>
                            </div>
                        </div>

                        {/* _______________________________________ */}

                        <div className="col-md-3">
                            <div className="productDetails">
                                <div className="imgDetails">
                                    <img src="assets/img/home/mcc-slider/wires-home.png" alt="product" />
                                </div>
                                <p>Wires and cables</p>
                            </div>
                        </div>
                    </div>

                    <div className="row mgnbginBottom">

                        {/* _______________________________________ */}

                        <div className="col-md-3">
                            <div className="productDetails">
                                <div className="imgDetails">
                                    <img src="assets/img/home/mcc-slider/MCB-home.png" alt="product" />
                                </div>
                                <p>Switchgear</p>
                            </div>
                        </div>

                        {/* _______________________________________ */}

                        <div className="col-md-3">
                            <div className="productDetails">
                                <div className="imgDetails">
                                    <img src="assets/img/home/mcc-slider/lamps-home.png" alt="product" />
                                </div>
                                <p>Lighting products</p>
                            </div>
                        </div>

                        {/* _______________________________________ */}

                        <div className="col-md-3">
                            <div className="productDetails">
                                <div className="imgDetails">
                                    <img src="assets/img/home/mcc-slider/boxes-and-enclosers.png" alt="product" />
                                </div>
                                <p>Enclosures</p>
                            </div>
                        </div>

                        {/* _______________________________________ */}

                        <div className="col-md-3">
                            <div className="productDetails">
                                <div className="imgDetails">
                                    <img src="assets/img/home/mcc-slider/cable-ties-home.png" alt="product" />
                                </div>
                                <p>Cable Ties</p>
                            </div>
                        </div>
                    </div>

                    {/* _______________________________________ */}

                    <div className="row mgnbginBottom">
                        <div className="col-md-3">
                            <div className="productDetails">
                                <div className="imgDetails">
                                    <img src="assets/img/home/mcc-slider/power-accessories-home.png" alt="product" />
                                </div>
                                <p>Power Accessories</p>
                            </div>
                        </div>

                        {/* _______________________________________ */}

                        <div className="col-md-3">
                            <div className="productDetails">
                                <div className="imgDetails">
                                    <img src="assets/img/home/mcc-slider/door-bell-home.png" alt="product" />
                                </div>
                                <p>Doorbells</p>
                            </div>
                        </div>


                        {/* _______________________________________ */}

                        <div className="col-md-3">
                            <div className="productDetails">
                                <div className="imgDetails">
                                    <img src="assets/img/home/mcc-slider/fasteners-home.png" alt="product" />
                                </div>
                                <p>Fasteners</p>
                            </div>
                        </div>

                        {/* _______________________________________ */}

                        <div className="col-md-3">
                            <div className="productDetails">
                                <div className="imgDetails">
                                    <img src="assets/img/home/mcc-slider/capacitors-home.png" alt="product" />
                                </div>
                                <p>Capacitors</p>
                            </div>
                        </div>
                    </div>

                    {/* _______________________________________ */}
                    <div className="row mgnbginBottom">

                        <div className="col-md-3">
                            <div className="productDetails">
                                <div className="imgDetails">
                                    <img src="assets/img/home/mcc-slider/paint_ch.png" alt="product" />
                                </div>
                                <p>Paint and chemicals</p>
                            </div>
                        </div>

                        {/* ________________________________ */}

                        <div className="col-md-3">
                            <div className="productDetails">
                                <div className="imgDetails">
                                    <img src="assets/img/home/mcc-slider/essentials-home.png" alt="product" />
                                </div>
                                <p>Essentials</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Products;