import React from 'react';

function ApplyLoan() {
    return (
        <>
            <section className="appyLoanSec">

                <div className="container">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <div className="mngLoanApply">
                                <div className='LoanDetailsYou'>
                                    <h5>Few Details To Get You Started</h5>
                                    <h2>Apply Now</h2>
                                </div>
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="formGroupExampleInput">Name</label>
                                        <input type="text" className="next-input form-control" id="formGroupExampleInput" placeholder="Your Name" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="formGroupExampleInput2">Email</label>
                                        <input type="email" className="next-input form-control" id="formGroupExampleInput2" placeholder="Your Email" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="formGroupExampleInput2">Mobile No.</label>
                                        <input type="number" className="next-input form-control" id="formGroupExampleInput3" placeholder="Contact Number" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="formGroupExampleInput2">Designation</label>
                                        <input type="text" className="next-input form-control" id="formGroupExampleInput4" placeholder="Your Designation" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="formGroupExampleInput2">Company</label>
                                        <input type="text" className="next-input form-control" id="formGroupExampleInput5" placeholder="Company Name" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="formGroupExampleInput2">Turnover</label>
                                        <input type="text" className="next-input form-control" id="formGroupExampleInput5" placeholder="Company Turnover" />
                                    </div>
                                    {/* <p className='mt-4 mb-4 '>Select Solution</p>
                                    <div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" defaultValue="option1" />
                                            <label className="form-check-label" htmlFor="inlineRadio1">1</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" defaultValue="option2" />
                                            <label className="form-check-label" htmlFor="inlineRadio2">2</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" defaultValue="option3" />
                                            <label className="form-check-label" htmlFor="inlineRadio3">3 (disabled)</label>
                                        </div>
                                    </div> */}

                                    <div className="text-center mt-3"><button type="button" className="inviteSuppliers">Submit</button></div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ApplyLoan;