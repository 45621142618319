import React from 'react'

function Faq() {
    return (
        <>
            <section class="innerpage-banner  faqs-banner">
                <div class="container">

                    <h4 class="breadcrumb text-white"> FAQs</h4>
                    <div class="innerpage-banner-content mx-auto ">
                        <div class="row">
                            <div class="col-lg-12 col-xs-12 my-auto">
                                <h1 class="text-left text-white mb-3">Frequently Asked <span class="pl-green">Questions</span></h1>
                            </div>

                        </div>

                    </div>
                </div>
            </section>

            <section className="faqsection">
                <div className="container">
                    <div className="row">
                        <div class="col-lg-12 faqmatter">
                            <div>
                                <h4>A. Pre-order: Queries before placing your order</h4>
                                <h4>B. Post-order: Queries regarding an already placed order</h4>
                            </div>
                            <hr />
                            <h6 class="pl-green">1. How to place orders in Astafa?</h6>
                            <p> To begin ordering, you may visit the Astafa website on your desktop or mobile browser. You may download and install the Astafa app to place an order.</p>
                            <div className='orderStep'>
                                <h4>Steps to place an order:</h4>
                                <ul>
                                    <li>Select the items which you want to order.</li>
                                    <li>Add those items to your “Shopping Cart”.</li>
                                    <li>After reviewing your shopping cart, click on “Proceed to Checkout”.</li>
                                    <li>You’ll be prompted to sign in to Astafa.in account, you can sign in using your mobile number or email address if you already have an account.</li>
                                    <li>If you’re a new customer, click on “I am a new customer” option on the sign in page to create a new account.</li>
                                    <li>Enter the address where you want to receive your order/select the Pickup store as per the availability by searching in the available criteria.</li>
                                    <li>Select the payment method, add the details and click on “Continue”.</li>
                                    <li>Review your order and click on “Place your Order” button and Pay to complete the transaction.</li>
                                </ul>
                                <p><b>Note:</b> The delivery speeds, dates and delivery address cannot be changed once an order is placed.</p>
                            </div>
                        </div>
                        {/* --------------------------------------------- */}

                        <div class="col-lg-12 faqmatter">
                            <hr />
                            <h6 class="pl-green">2. How to change quantity of orders in the cart?</h6>
                            <p className='faqhightlight'>To change the quantity for items you have not yet added to the cart -</p>
                            <p>Once you click on the "Add to Cart" button and add the item to your shopping cart, enter the desired quantity in the "Quantity" dropdown option on the right side of the page.</p>
                            <p className='faqhightlight'>To change the quantity for items already added to the cart -</p>
                            <p>Go to Cart, click on the quantity dropdown box to the right of the title and choose the desired quantity. The quantity number for the item and order amount will be updated automatically.</p>
                            <p>If the entered quantity is not available with us, you'll see an error message.</p>

                        </div>

                        {/* --------------------------------------------- */}

                        <div class="col-lg-12 faqmatter">
                            <hr />
                            <h6 class="pl-green">3. How to place Bulk Orders?</h6>
                            <p>You can bulk order new products from Astafa Business. To order, select the following link: <a style={{ textDecoration: "underline" }} href="https://astafa.in/" target='_blank'>https://astafa.in/</a></p>
                            <p>You can also access Bulk Ordering by hovering over your Account for Business menu and selecting Bulk Ordering from the drop-down menu. Enter the ISBNs or ASINs into the online form, or download a copy of the form to fill out and upload later.</p>
                            <p>Astafa Business offers the option to bulk buy products for business customers at no extra cost. You can also get an option of quantity discount.</p>

                        </div>

                        {/* --------------------------------------------- */}

                        <div class="col-lg-12 faqmatter">
                            <hr />
                            <h6 class="pl-green">4. Why am I unable to place orders?</h6>
                            <p>This could be due to the following issues:</p>
                            <div>
                                <ul>
                                    <li>Check if there is a Payment decline. Please click here to know more about Payment issues.</li>
                                    <li>The item you have selected is out of stock.</li>
                                    <li>There is some technical or internet connectivity issue.</li>
                                    <li>Undeliverable to selected location/pincode.</li>
                                    <li>Check if there is a quantity limit.</li>
                                    <li>Sometimes when FBA and seller fulfilled items are clubbed together.</li>
                                </ul>
                            </div>
                        </div>

                        {/* --------------------------------------------- */}

                        <div class="col-lg-12 faqmatter">
                            <hr />
                            <h6 class="pl-green">5. What is Cash on Delivery?</h6>
                            <p>Cash on Delivery is one of the payment methods for making purchases on Astafa.in. When you select Cash on Delivery as your payment method, you don't have to make any advance payment. You pay for your order only when you receive it. Click here to know more about Cash on Delivery.</p>
                        </div>

                        {/* --------------------------------------------- */}

                        <div class="col-lg-12 faqmatter">
                            <hr />
                            <h6 class="pl-green">6. How to change the payment method?</h6>
                            <p>You can add or update your payment methods by selecting the payments options section in Your Account.</p>
                        </div>

                        {/* --------------------------------------------- */}

                        <div class="col-lg-12 faqmatter">
                            <hr />
                            <h6 class="pl-green">7. Can I change or modify orders that are already placed?</h6>
                            <p>You would not be able to change or modify the number of items once you have already placed an order. You can place another fresh order with the desired modifications. However, you can change your shipping preferences in <b>Your Account</b> at any time after placing your order as long as the order hasn't entered the shipping process yet.</p>
                        </div>

                        <div class="col-lg-12 faqmatter">
                            <hr />
                            <h6 class="pl-green">8. I ordered a wrong item. How can I stop the order?</h6>
                            <p>If you have ordered a wrong item, you can cancel the order. In case, the order is already shipped and out for delivery, you can return the order.</p>
                        </div>

                        {/* ---------------------------------------------------- */}

                        <div class="col-lg-12 faqmatter">
                            <hr />
                            <h6 class="pl-green">9. How to cancel orders?</h6>
                            <p>You can cancel items or orders by visiting the <b>Your Orders</b> section in <b>Your Account.</b></p>
                            <div className='orderStep'>
                                <p className='faqhightlight'>To cancel orders that aren't dispatched yet:</p>
                                <ul>
                                    <li>Go to Your Orders.</li>
                                    <li>Select the item you want to cancel and click Cancel items.</li>
                                    <li>Provide reasons for cancellation (optional).</li>
                                    <li>Click on Cancel Checked Items.</li>
                                </ul>

                                <p className='faqhightlight'>To cancel an order that has already been dispatched:</p>
                                <ul>
                                    <li>Go to Your Orders.</li>
                                    <li>Select the Request cancellation option and proceed further.</li>
                                    <li>The item(s) will be returned to us for a refund (if the payment is already made).</li>
                                </ul>

                                <p><b>Note:</b> In case you're still contacted for delivery, please refuse to accept it.</p>
                            </div>
                        </div>

                        {/* --------------------------------------------- */}

                        <div class="col-lg-12 faqmatter">
                            <hr />
                            <h6 class="pl-green">10. How can I order the same items again?</h6>
                            <p>Follow the steps below to order an item again -</p>
                            <div className='orderStep'>
                                <ul>
                                    <li>Go to Your Orders.</li>
                                    <li>Click on the Buy Again Tab. You will see a list of items you had previously purchased.</li>
                                    <li>Add to Cart the items you wish to purchase again.</li>
                                    <li>Go to the cart and follow the onscreen instruction to complete the order.</li>
                                </ul>
                            </div>
                            <p><b>Note:</b> Items that are returned will not be displayed in the Buy Again tab.</p>
                        </div>

                        {/* --------------------------------------------- */}

                        <div class="col-lg-12 faqmatter">
                            <hr />
                            <h6 class="pl-green">11. How to track orders?</h6>
                            <p>The products ordered by you on Astafa.in are delivered in following ways:</p>
                            <div className='orderStep'>
                                <ul>
                                   <li>Astafa fulfilled delivery</li>
                                   <li>Seller fulfilled delivery</li>
                                   <li>Delivery by third party carriers</li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Faq;