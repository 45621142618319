import React from 'react'
import { Link } from 'react-router-dom';

function ContactUs() {
    return (
        <>
            <div>
                <section className="innerpage-banner  contactsupport-banner">
                    <div className="container">
                        <h4 className="breadcrumb text-white">Support</h4>
                        <div className="innerpage-banner-content mx-auto ">
                            <div className="row">
                                <div className="col-lg-10 col-xs-12 my-auto">
                                    <h1 className="text-left text-white mb-3">Happy to <span className="pl-green">help</span>   you!</h1>
                                    <p className="text-white">We’re just a click or call away. Our support team is available 7 days a week to answer any questions you have and provide you with timely solutions</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*------------------Products
----------------------------------*/}
                <section className="contactsales-section mt-5">
                    <span className=" green-shadow-blur-right" />
                    <div className="container register getaQuoteRegister">
                        <div className="row">
                            <div className="col-md-3 register-left">

                                <h3>Welcome</h3>

                                <img src="assets/img/send_mail.png" alt="" />

                            </div>
                            <div className="col-md-9 register-right">

                                <div className="row register-form">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Name" />
                                        </div>
                                        <div className="form-group">
                                            <input type="text" minLength={10} maxLength={10} className="form-control" placeholder="Mobile Number" />
                                        </div>
                                        <div className="form-group">
                                            <input type="email" className="form-control" placeholder="Email" />
                                        </div>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Name of company/supplier" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="GST No." />
                                        </div>

                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Product Details" />
                                        </div>



                                        <div className="form-group">
                                            <input type="text" name="txtEmpPhone" className="form-control" placeholder="Quantity" />
                                        </div>

                                        <div className="form-group">
                                            <input type="text" name="txtEmpPhone" className="form-control" placeholder="Delivery" />
                                        </div>


                                    </div>
                                    <div className="col-md-12 text-center">
                                        <button type="submit" className="btnRegister" >Submit</button>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </section>

                {/* <section className='contactMapSrc'>
                    <div className="container">
                        <div className="row">
                            <div className="mapouter">
                                <div className="gmap_canvas">
                                    <iframe width="100%" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=acenna&t=&z=17&ie=UTF8&iwloc=&output=embed" />
                                    <br /><style dangerouslySetInnerHTML={{ __html: ".mapouter{position:relative;text-align:right;height:500px;width:100%;}" }} /><a href="https://www.embedgooglemap.net" /><style dangerouslySetInnerHTML={{ __html: ".gmap_canvas {overflow:hidden;background:none!important;height:500px;width:100%;}" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

            </div>

        </>
    )
}

export default ContactUs;