import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
	return (
		<>
			<div>
				<section className="hero-banner herobannertop">
					<div className="container">
						<div className="hero-banner-content  mx-auto">
							<h1>B2B e-commerce platform for <br /> building and electrical materials.</h1>
							<div className="col-lg-8 col-md-9 col-sm-12 col-xs-12 page-header-content mx-auto ">
								With embedded credit, the platform integrates merchants/shop and manufactures/suppliers for key building/ electrical materials at better price and convenience.
								<div className="mx-auto mt-4"> <a href="#" target="_blank" className=" btn btn-primary-green">
									<img src="assets/img/play-ic.png" alt="play" className="play-icon pr-2" />Watch Video</a>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section id="home-slider" className="home-slider mt-5">
					<div className="slideshow">
						<div className="owl-carousel owl-theme">
							<div className="item">
								<a href="#">
									<picture className="front_img_slide">
										<source media="(min-width: 768px) and (max-width:2560)" srcSet="assets/img/home/home-banner/banner_1.png" alt="A one-stop app for all things business." />
										<source media="(min-width: 461px) and (max-width:767px)" srcSet="assets/img/home/home-banner/banner_1.png" alt="A one-stop app for all things business." />
										<source media="(min-width: 0px) and (max-width:460px)" srcSet="assets/img/home/home-banner/banner_1.png" alt="A one-stop app for all things business." />
										<img srcSet="assets/img/home/home-banner/banner_1.png" alt="A one-stop app for all things business." />
									</picture>
								</a>
								<div className="cover">
									<div className="banner_text">
										<div className="banner_header"><span className="teal">Grow Your Business <br /></span>with our network </div>
									</div>
								</div>
							</div>
							<div className="item ">
								<a href="#">
									<picture className="front_img_slide">
										<source media="(min-width: 768px) and (max-width:2560)" srcSet="assets/img/home/home-banner/banner_2.png" alt="all-in-one terminal" />
										<source media="(min-width: 461px) and (max-width:767px)" srcSet="assets/img/home/home-banner/banner_2.png" alt="all-in-one terminal" />
										<source media="(min-width: 300px) and (max-width:460px)" srcSet="assets/img/home/home-banner/banner_2.png" alt="all-in-one terminal" />
										<img srcSet="assets/img/home/home-banner/banner_2.png" alt="all-in-one terminal." />
									</picture>
								</a>
								<div className="cover">
									<div className="banner_text">
										<div className="banner_header">An <span className="teal">Quick next </span>day delivery</div>
									</div>
								</div>
							</div>
							<div className="item">
								<a href="#">
									<picture className="myEmi">
										<source media="(min-width: 601px) and (max-width:2560)" srcSet="assets/img/home/home-banner/banner_3.png" alt="Say hello to myEMI with Pine Labs" />
										<source media="(min-width: 461px) and (max-width:600px)" srcSet="assets/img/home/home-banner/banner_3.png" alt="Say hello to myEMI with Pine Labs" />
										<source media="(min-width: 300px) and (max-width:460px)" srcSet="assets/img/home/home-banner/banner_3.png" alt="Say hello to myEMI with Pine Labs" />
										<img srcSet="assets/img/home/home-banner/banner_3.png" alt="Say hello to myEMI with Pine Labs" />
									</picture>
								</a>
								<div className="cover  akbanner_text">
									<div className="banner_text">
										<div className="banner_header">
											<span className="banner-mid-heading">Buy materials</span>
											<div className="myemi-text">on<span className="emi-text">CREDIT</span></div>
											<div className="banner-small-heading">Credit with no cost * and immediate <br />payment to the supplier.
											</div>
											<div className="col-md-12 p-0 mt-4">
												<a className="btn-small btn btn-primary-green text-white py-2 px-3 font-weight-medium" target="_blank" href="#"> Know
													more</a>
											</div>
										</div>
									</div>
								</div>
							</div>
							{/*	<div class="item">
				<a href="contact-sales">
				<picture class="front_img_slide">
					<source media="(min-width: 768px) and (max-width:2560)" srcset="assets/img/home/home-banner/pinelabs-payments.png?version=6.1.4" alt="Fintech Interconnected" >
					<source media="(min-width: 461px) and (max-width:767px)" srcset="assets/img/home/home-banner/pinelabs-payments.png?version=6.1.4" alt="Fintech Interconnected" >
					<source media="(min-width: 300px) and (max-width:460px)" srcset="assets/img/home/home-banner/pinelabs-payments.png?version=6.1.4" alt="Fintech Interconnected" >
					<img srcset="assets/img/home/home-banner/pinelabs-payments.png?version=6.1.4" alt="Fintech Interconnected" >
				</picture></a>
				<div class="cover">
					<div class="banner_text">
						<div class="banner_header">Our<img src="assets/img/fav-green-icon.png?version=6.1.4" alt="Pine Labs Payments" class="payment-arrow"><br>platform,<br><span class="teal">your <br>move.</span> </div>
					</div>   
				</div>
			</div> 
*/}
							<div className="item">
								<a href="#">
									<picture className="front_img_slide">
										<source media="(min-width: 768px) and (max-width:2560)" srcSet="assets/img/home/home-banner/banner_4.png" alt="Plutus Smart – Swipe machine " />
										<source media="(min-width: 461px) and (max-width:767px)" srcSet="assets/img/home/home-banner/banner_4.png" alt="Plutus Smart – Swipe machine " />
										<source media="(min-width: 300px) and (max-width:460px)" srcSet="assets/img/home/home-banner/banner_4.png" alt="Plutus Smart – Swipe machine " />
										<img srcSet="assets/img/home/home-banner/banner_4.png" alt="Plutus Smart – Swipe machine " />
									</picture>
								</a>
								<div className="cover">
									<div className="banner_text">
										<div className="banner_header">Buy at convenience,<img src="assets/img/fav-green-icona6d4.png?version=6.1.4" alt="Pine Labs Payments" className="payment-arrow" />
										</div>
									</div>
								</div>
							</div>

							{/*
			<div class="item">
				<a href="#">
				<picture class="front_img_slide">
					<source media="(min-width: 768px) and (max-width:2560)" srcset="assets/img/home/home-banner/paylater.png?version=6.1.4" alt="buy now pay later"  >
					<source media="(min-width: 461px) and (max-width:767px)" srcset="assets/img/home/home-banner/paylater.png?version=6.1.4" alt="buy now pay later"  >
					<source media="(min-width: 300px) and (max-width:460px)" srcset="assets/img/home/home-banner/paylater.png?version=6.1.4" alt="buy now pay later"  >
					<img srcset="assets/img/home/home-banner/paylater.png?version=6.1.4" alt="buy now pay later"  >
				</picture></a>
				<div class="cover">
					<div class="banner_text">
						<div class="banner_header">Boost<br> sales with<br> Buy now,<br><span class="light-blue">PAY LATER<img src="assets/img/fav-teal-icon.png?version=6.1.4" alt="Pine Labs Payments" class="payment-arrow teal-arrow"></span> </div>
					</div>   
				</div>
			</div>	
*/}
						</div>
					</div>
				</section>
				<section className="mcc-slider overflow-hidden   space-ptb space-pb" id="growing-business">
					<div className="container">
						<h2 className="text-left"><span className="pl-green">Buy Products</span><br /> Across Categories</h2>
						<div className="mcc-owl-crousel pt-4">
							<span className=" green-shadow-blur-right" />
							<div id="slider-carousel" className="owl-carousel">
								<div className="item">
									<a className="hoverfx" href="#">
										<div className="mcc-arrow bg-pl-new_teal btn-arrow">
											<span className="arrow-white" data-aos="fade-in-right" data-aos-duration="linear" />
										</div>
										<img src="assets/img/home/mcc-slider/switch_socket.png" alt="electronics" />
									</a>
									<h3 className="slide-content text-white">Switches and sockets</h3>
								</div>
								<div className="item">
									<a className="hoverfx" href="#">
										<div className="mcc-arrow bg-pl-new_teal btn-arrow">
											<span className="arrow-white" data-aos="fade-in-right" data-aos-duration="linear" />
										</div>
										<img src="assets/img/home/mcc-slider/conduit-pipes.png" alt="lifestyle" />
									</a>
									<h3 className="slide-content text-white">PVC conduit pipes</h3>
								</div>
								<div className="item">
									<a className="hoverfx" href="#">
										<div className="mcc-arrow bg-pl-new_teal btn-arrow">
											<span className="arrow-white" data-aos="fade-in-right" data-aos-duration="linear" />
										</div>
										<img src="assets/img/home/mcc-slider/casing-capping.png" alt="automobile" />
									</a>
									<h3 className="slide-content text-white">Casing- Capping</h3>
								</div>
								<div className="item">
									<a className="hoverfx" href="#">
										<div className="mcc-arrow bg-pl-new_teal btn-arrow">
											<span className="arrow-white" data-aos="fade-in-right" data-aos-duration="linear" />
										</div>
										<img src="assets/img/home/mcc-slider/wires-home.png" alt="grocery" />
									</a>
									<h3 className="slide-content text-white">Wires and cables</h3>
								</div>
								<div className="item">
									<a className="hoverfx" href="#">
										<div className="mcc-arrow bg-pl-new_teal btn-arrow">
											<span className="arrow-white" data-aos="fade-in-right" data-aos-duration="linear" />
										</div>
										<img src="assets/img/home/mcc-slider/MCB-home.png" alt="healthcare" />
									</a>
									<h3 className="slide-content text-white">Switchgear</h3>
								</div>
								<div className="item">
									<a className="hoverfx" href="#">
										<div className="mcc-arrow bg-pl-new_teal btn-arrow">
											<span className="arrow-white" data-aos="fade-in-right" data-aos-duration="linear" />
										</div>
										<img src="assets/img/home/mcc-slider/lamps-home.png" alt="hospitality" />
									</a>
									<h3 className="slide-content text-white">Lighting products</h3>
								</div>
								{/* ------------------------------- */}
								<div className="item">
									<a className="hoverfx" href="#">
										<div className="mcc-arrow bg-pl-new_teal btn-arrow">
											<span className="arrow-white" data-aos="fade-in-right" data-aos-duration="linear" />
										</div>
										<img src="assets/img/home/mcc-slider/boxes-and-enclosers.png" alt="hospitality" />
									</a>
									<h3 className="slide-content text-white">Enclosures</h3>
								</div>

								<div className="item">
									<a className="hoverfx" href="#">
										<div className="mcc-arrow bg-pl-new_teal btn-arrow">
											<span className="arrow-white" data-aos="fade-in-right" data-aos-duration="linear" />
										</div>
										<img src="assets/img/home/mcc-slider/cable-ties-home.png" alt="hospitality" />
									</a>
									<h3 className="slide-content text-white">Cable Ties</h3>
								</div>

								<div className="item">
									<a className="hoverfx" href="#">
										<div className="mcc-arrow bg-pl-new_teal btn-arrow">
											<span className="arrow-white" data-aos="fade-in-right" data-aos-duration="linear" />
										</div>
										<img src="assets/img/home/mcc-slider/power-accessories-home.png" alt="hospitality" />
									</a>
									<h3 className="slide-content text-white">Power Accessories</h3>
								</div>

								<div className="item">
									<a className="hoverfx" href="#">
										<div className="mcc-arrow bg-pl-new_teal btn-arrow">
											<span className="arrow-white" data-aos="fade-in-right" data-aos-duration="linear" />
										</div>
										<img src="assets/img/home/mcc-slider/door-bell-home.png" alt="hospitality" />
									</a>
									<h3 className="slide-content text-white">Doorbells</h3>
								</div>

								<div className="item">
									<a className="hoverfx" href="#">
										<div className="mcc-arrow bg-pl-new_teal btn-arrow">
											<span className="arrow-white" data-aos="fade-in-right" data-aos-duration="linear" />
										</div>
										<img src="assets/img/home/mcc-slider/fasteners-home.png" alt="hospitality" />
									</a>
									<h3 className="slide-content text-white">Fasteners</h3>
								</div>

								<div className="item">
									<a className="hoverfx" href="#">
										<div className="mcc-arrow bg-pl-new_teal btn-arrow">
											<span className="arrow-white" data-aos="fade-in-right" data-aos-duration="linear" />
										</div>
										<img src="assets/img/home/mcc-slider/capacitors-home.png" alt="hospitality" />
									</a>
									<h3 className="slide-content text-white">Capacitors</h3>
								</div>
								<div className="item">
									<a className="hoverfx" href="#">
										<div className="mcc-arrow bg-pl-new_teal btn-arrow">
											<span className="arrow-white" data-aos="fade-in-right" data-aos-duration="linear" />
										</div>
										<img src="assets/img/home/mcc-slider/paint_ch.png" alt="hospitality" />
									</a>
									<h3 className="slide-content text-white">Paint and chemicals</h3>
								</div>
								<div className="item">
									<a className="hoverfx" href="#">
										<div className="mcc-arrow bg-pl-new_teal btn-arrow">
											<span className="arrow-white" data-aos="fade-in-right" data-aos-duration="linear" />
										</div>
										<img src="assets/img/home/mcc-slider/essentials-home.png" alt="hospitality" />
									</a>
									<h3 className="slide-content text-white">Essentials</h3>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/*------------------Products
----------------------------------*/}
				<section className="product-brief-block" id="our-suite">
					<div className="container">
						<div className="row">
							<div className="col-lg-8 col-md-8 col-sm-12 col-xs-12  ">
								<h2 className="text-left mt-5">Our toolkit for a <br /><span className="pl-green">firm with a bright future</span></h2>
								<div className="top-products-details pt-4">
									<nav>
										<ul className="nav darkgreen-tabs nav-tabs" id="topproductstab" role="tablist">
											<li className="nav-item">
												<a className="h4 nav-link active" id="topproductstab-tab-1" data-toggle="tab" href="#topproductstab-1" role="tab" aria-controls="topproductstab-1" aria-selected="true">
													<span className="paylater-icon icon-64" />
													<div className="tablink">Our Suite</div>
												</a>
											</li>
											<li className="nav-item">
												<a className="h4 nav-link" id="topproductstab-tab-2" data-toggle="tab" href="#topproductstab-2" role="tab" aria-controls="topproductstab-2" aria-selected="false">
													<span className="storedvalue-icon icon-64" />
													<div className="tablink">Warehouses/manufacturer</div>
												</a>
											</li>
											<li className="nav-item">
												<a className="h4 nav-link" id="topproductstab-tab-3" data-toggle="tab" href="#topproductstab-3" role="tab" aria-controls="topproductstab-3" aria-selected="false">
													<span className="techfirst-icon icon-64" />
													<div className="tablink">Merchants</div>
												</a>
											</li>
										</ul>
									</nav>
									<div className="tab-content" id="topproductstabcontent">
										<div className="tab-pane   zoomIn active" id="topproductstab-1" role="tabpanel" aria-labelledby="topproductstab-tab-1">
											<div className="col-12">
												<div className="vertical-tabs py-5">
													<div className="row">
														<div className="col-md-4 col-4   p-0">
															<div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
																<a className="h5 nav-link active" id="v-pills-one-tab" data-toggle="pill" href="#v-pills-one" role="tab" aria-controls="v-pills-one" aria-selected="true">Integrated Platform</a>
																<a className="h5 nav-link" id="v-pills-two-tab" data-toggle="pill" href="#v-pills-two" role="tab" aria-controls="v-pills-two" aria-selected="false">Credit Purchase</a>
																<a className="h5 nav-link" id="v-pills-three-tab" data-toggle="pill" href="#v-pills-three" role="tab" aria-controls="v-pills-three" aria-selected="false">Wide Network</a>
																<a className="h5 nav-link" id="v-pills-four-tab" data-toggle="pill" href="#v-pills-four" role="tab" aria-controls="v-pills-four" aria-selected="false">End-to-end  payment Solution </a>
																<a className="h5 nav-link" id="v-pills-five-tab" data-toggle="pill" href="#v-pills-five" role="tab" aria-controls="v-pills-five" aria-selected="false">Loyalty Membership</a>
															</div>
														</div>
														<div className="col-md-7 col-7   p-0 offset-1">
															<div className="tab-content" id="v-pills-tabContent">
																<div className="tab-pane fade show active" id="v-pills-one" role="tabpanel" aria-labelledby="v-pills-one-tab">
																	<span className="arrow-grey bg-pl-light-grey" />
																	<div className="ml-3">shops and manufactures</div>
																</div>
																{/* <div className="tab-pane fade firsttabspace" id="v-pills-two" role="tabpanel" aria-labelledby="v-pills-two-tab">
																	<span className="arrow-grey bg-pl-light-grey" data-aos="fade-right" />
																	<div className="ml-3"> Offer Pay Later even on smaller transactions
																		through UPI</div>
																</div>
																<div className="tab-pane fade secondtabspace" id="v-pills-three" role="tabpanel" aria-labelledby="v-pills-three-tab">
																	<span className="arrow-grey bg-pl-light-grey" data-aos="fade-right" />
																	<div className="ml-3">Boost eCommerce sales with our flexible Pay
																		Later plans now also on our online gateway.</div>
																</div> */}
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="tab-pane fade loyalty-tab " id="topproductstab-2" role="tabpanel" aria-labelledby="topproductstab-tab-2">
											<div className="col-12">
												<div className="vertical-tabs py-5">
													<div className="row">
														<div className="col-md-4 col-4  p-0">
															<div className="nav flex-column nav-pills" id="tp2-pills-tab" role="tablist" aria-orientation="vertical">
																<a className="h5 nav-link active" id="tp2-pills-one-tab" data-toggle="pill" href="#tp2-pills-one" role="tab" aria-controls="tp2-pills-one" aria-selected="true">Warehouses/manufacturer</a>
															</div>
														</div>
														{/* <div className="col-md-7 col-7   p-0 offset-1">
															<div className="tab-content" id="tp2-pills-tabContent">
																<div className="tab-pane fade show active" id="tp2-pills-one" role="tabpanel" aria-labelledby="tp2-pills-one-tab">
																	<span className="arrow-grey bg-pl-light-grey" data-aos="fade-right" />
																	<div className="ml-3">Turn your customers into regulars with
																		customisable loyalty plans that work for you.</div>
																</div>
																<div className="tab-pane fade firsttabspace" id="tp2-pills-two" role="tabpanel" aria-labelledby="tp2-pills-two-tab">
																	<span className="arrow-grey bg-pl-light-grey" data-aos="fade-right" />
																	<div className="ml-3">Introduce new customers to your inventory and
																		boost sales through easy-to-dispense gift cards and
																		vouchers. </div>
																</div>
															</div>
														</div> */}
													</div>
												</div>
											</div>
										</div>
										<div className="tab-pane fade  " id="topproductstab-3" role="tabpanel" aria-labelledby="topproductstab-tab-3">
											<div className="col-12">
												<div className="vertical-tabs py-5">
													<div className="row">
														<div className="col-md-4 col-4  p-0">
															<div className="nav flex-column nav-pills" id="tp3-pills-tab" role="tablist" aria-orientation="vertical">
																<a className="h5 nav-link active" id="tp3-pills-one-tab" data-toggle="pill" href="#tp3-pills-one" role="tab" aria-controls="v-pills-one" aria-selected="true">Merchants</a>
																{/* <a className="h5 nav-link" id="tp3-pills-two-tab" data-toggle="pill" href="#tp3-pills-two" role="tab" aria-controls="tp3-pills-two" aria-selected="false">Working Capital</a>
																<a className="h5 nav-link" id="tp3-pills-three-tab" data-toggle="pill" href="#tp3-pills-three" role="tab" aria-controls="tp3-pills-three" aria-selected="false">Utility
																	App Integrations</a> */}
															</div>
														</div>
														{/* <div className="col-md-7 col-7   p-0 offset-1">
															<div className="tab-content" id="tp3-pills-tabContent">
																<div className="tab-pane fade show active" id="tp3-pills-one" role="tabpanel" aria-labelledby="tp3-pills-one-tab">
																	<span className="arrow-grey bg-pl-light-grey" data-aos="fade-right" />
																	<div className="ml-3">Track your customers’ spending behaviour and
																		boost sales through targeted advertising.</div>
																</div>
																<div className="tab-pane fade firsttabspace" id="tp3-pills-two" role="tabpanel" aria-labelledby="tp3-pills-two-tab">
																	<span className="arrow-grey bg-pl-light-grey" data-aos="fade-right" />
																	<div className="ml-3">Get access to ready capital when you need to
																		meet demand and pay it back from your device itself. </div>
																</div>
																<div className="tab-pane fade secondtabspace" id="tp3-pills-three" role="tabpanel" aria-labelledby="tp3-pills-three-tab">
																	<span className="arrow-grey bg-pl-light-grey" data-aos="fade-right" />
																	<div className="ml-3">Seamlessly integrate leading software and apps
																		for your industry into our open platform. </div>
																</div>
															</div>
														</div> */}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-4  d-md-block d-none  mt-md-5">
								<span className=" green-shadow-blur-right" />
								<img src="assets/img/contact/paylater.png" alt="our Payment Solutions" className="our-suite-img blur-0" />
							</div>
						</div>
					</div>
				</section>
				{/*------------------Feature Product
----------------------------------*/}
				<section className="payment-block space-ptb">
					<div className="container">
						<div className="line-grid-management">
							<h2 className=" text-center mb-md-5 mb-sm-0"><span className="pl-green">Benefits to shops for </span> <br />working with Astafa
							</h2>
							<div className="payment-block-content pt-4">
								<div className="col-lg-12 col-md-12 col-sm-12  col-xs-12 p-0">
									<div className="line-grid-small-bg" />
									<div className="row row-flex">
										<div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 d-flex  align-items-stretch">
											<div className="card">
												<div className="card-body">
													<img src="assets/img/icons/dollar.png" className="icon-64" alt="In Store payments" />
													<h4 className="pt-3 pl_dark-grey">Prices</h4>
													<p className="card-text">Better prices with convenience, we guarantee discounted rates from market.</p>
													<a href="#" className="btn-pl-green">Learn more <i className="pl-1 fa fa-angle-right" /></a>
												</div>
											</div>
										</div>
										<div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 d-flex  align-items-stretch">
											<div className="card">
												<div className="card-body">
													<img src="assets/img/icons/shopping-bag.png" className="icon-64" alt="Online payments" />
													<h4 className="pt-3  pl_dark-grey">Order</h4>
													<p className="card-text">Convenience of order (app, website, WhatsApp, call or in person)<br /><br /></p>
													<a href="#" className="btn-pl-green">Learn more <i className="pl-1 fa fa-angle-right" /></a>
												</div>
											</div>
										</div>
										<div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 d-flex  align-items-stretch">
											<div className="card">
												<div className="card-body">
													<img src="assets/img/icons/hassle-free.png" className="icon-64" alt="Omnichannel" />
													<h4 className="pt-3  pl_dark-grey">Hassle-Free</h4>
													<p className="card-text">Hassle-free next day delivery: we cover more than 6500 pins across India<br /><br /></p>
													<a href="#" className="btn-pl-green">Learn more <i className="pl-1 fa fa-angle-right" /></a>
												</div>
											</div>
										</div>
										<div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 d-flex  align-items-stretch">
											<div className="card">
												<div className="card-body">
													<img src="assets/img/icons/credit-card.png" className="icon-64" alt="Omnichannel" />
													<h4 className="pt-3  pl_dark-grey">Credit</h4>
													<p className="card-text">Instant credit up to Rs. 5 lacs with no hidden fees<br /><br /></p>
													<a href="#" className="btn-pl-green">Learn more <i className="pl-1 fa fa-angle-right" /></a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/*------------------360 degree payment
----------------------------------*/}
				{/* <section className="payment-block space-ptb">
					<div className="container">
						<div className="line-grid-small ">
							<h2 className=" text-left mb-md-5 mb-sm-0"><span className="pl-green">360-degree</span> <br />Payment Solutions
							</h2>
							<div className="payment-block-content pt-4">
								<div className="col-lg-10 col-lg-offset-2 col-md-11 col-sm-12  col-xs-12 p-0">
									<div className="line-grid-small-bg" />
									<div className="row row-flex">
										<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 d-flex  align-items-stretch">
											<div className="card">
												<div className="card-body">
													<img src="assets/img/icons/setup-payments/default-state/PoS_Defa6d4.svg?version=6.1.4" className="icon-64" alt="In Store payments" />
													<h4 className="pt-3 pl_dark-grey">In-Store</h4>
													<p className="card-text">Accept all major payment modes and upgrade your customer
														experience.</p>
													<a href="#" className="btn-pl-green">Learn more <i className="pl-1 fa fa-angle-right" /></a>
												</div>
											</div>
										</div>
										<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 d-flex  align-items-stretch">
											<div className="card">
												<div className="card-body">
													<img src="assets/img/icons/setup-payments/default-state/Online_Defa6d4.svg?version=6.1.4" className="icon-64" alt="Online payments" />
													<h4 className="pt-3  pl_dark-grey">Online</h4>
													<p className="card-text">Make online payments faster, simpler and
														error-free.<br /><br /></p>
													<a href="#" className="btn-pl-green">Learn more <i className="pl-1 fa fa-angle-right" /></a>
												</div>
											</div>
										</div>
										<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 d-flex  align-items-stretch">
											<div className="card">
												<div className="card-body">
													<img src="assets/img/icons/setup-payments/default-state/Omnichannel_Defa6d4.svg?version=6.1.4" className="icon-64" alt="Omnichannel" />
													<h4 className="pt-3  pl_dark-grey">Omnichannel</h4>
													<p className="card-text">Sell in-store and online seamlessly.<br /><br /></p>
													<a href="#" className="btn-pl-green">Learn more <i className="pl-1 fa fa-angle-right" /></a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section> */}
				{/*------------------Partner testimonials/merchant stories
----------------------------------*/}
				<section className="merchant-stories-block homepage-ms space-ptb">
					<div className="container">
						<h2 className="text-left">Our <span className="pl-green">Partnerships</span></h2>
						<div className="payment-block-content pt-4">
							<div className="col-12">
								<div className="row">
									<div className=" col-md-6 col-xs-12 p-0 my-auto">
										<span className=" green-shadow-blur-left" />
										<div className="merchant-stories-member  blur-0">
											<div className="merchant-stories-content">
												<div className="merchant-stories-quotes mb-3">
													<div className="quote-left">
														<span>“</span>
													</div>
													<div className="quote-content">
														<p className="italic">Our merchant / distributor network is unmatched.
															Be part of greater community and grow your business at ease”</p>
														<h6 className="pl-green">Name</h6>
														<p className="small"> Designation/Merchant
														</p>
													</div>
												</div>
												<a className="px-3 btn btn-secondary-green" href="#">
													Read Merchant Story
												</a>
											</div>
										</div>
									</div>
									<div className="col-md-6 col-xs-12 p-0">
										<div className="merchant-stories-img d-block">
											<img src="assets/img/contact/ourPatner.jpg" className="d-block w-100" alt="daniel wellington watches" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/*------------------Client logo
----------------------------------*/}
			
				{/* get strated block  started*/}
				<section className="get-started space-ptb" id="ticker">
					<div className="container">
						<div className="get-started-content pb-5 text-center">
							<div className="page-header-message pb-5">Let’s get <span className="pl-green">started</span></div>
							<div className="web-hov-buttons d-md-block d-none">
								<Link to="/contact" className="btn btn-secondary-green" id="newto">New to Astafa?</Link>
								<Link to="/contact" className="btn btn-secondary-blue" id="alreadywithpinelabs">Already with Astafa?<span /></Link>
							</div>
							<div className="mobile-hov-buttons mobile-hov-buttons d-md-none d-xs-block">
								<p className="my-2">New to Astafa?</p>
								<Link to="/contact" className="btn btn-primary-green   btn-arrow" id>Get Started<span className /></Link>
								<p className="my-2">Already with Astafa?</p>
								<Link to="/contact" className="btn btn-primary-blue   btn-arrow" id>Get More<span className /></Link>
							</div>
						</div>
					</div>
				</section>
			</div>

		</>
	)
}

export default Home;